import { LampBranchDto, LampDto, SwitchPointDto } from "../interfaces";
import { LampList } from "../components/Lamp";
import { LampBranchCardsWrapper } from "../components/LampBranch/LampBranchCardsWrapper";
import { LampCreateForm } from "../components/Lamp/LampCreateForm";
import { getQrCodeItemForLamp } from "../tech";
import { useEffect } from "react";
import { downloadLampListTable, getLampListInLampBranch } from "../apis/lampBranch/LampBranchApis";

import {
	isApiStateDispatched,
	normalizeText,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useActiveLau,
	useApiState,
} from "@pasport/react-common";
import { AddIcon, DetailIcon, DownloadIcon, ListIcon } from "@pasport/react-ui";
import { DownloadTableTile } from "../common/DownloadTableTile";
import { useUrlParams } from "../core/routing";


interface LampBranchScreenProps {
	lampBranchDetail: LampBranchDto;
	switchPointDetail: SwitchPointDto;
}

export const LampBranchScreen = ({ lampBranchDetail, switchPointDetail }: LampBranchScreenProps) => {
	const { activeLau } = useActiveLau();
	const { vetevId, zapinaciBodId } = useUrlParams();

	const {
		fetchApiState: dispatchLampList,
		apiState: lampListApiState,
		invalidateApiState: invalidateLampList,
	} = useApiState<LampDto[]>();


	useEffect(() => {
		if (!isApiStateDispatched(lampListApiState))
			dispatchLampList(() => getLampListInLampBranch(vetevId, zapinaciBodId));
	}, [lampListApiState]);

	if (!activeLau)
		return <></>;

	return (
		<Page>
			<PageHeader title={"Větev veřejného osvětlení " + lampBranchDetail.oznaceni} />
			<TabRoutes
				links={[
					{
						to: "seznam-lamp",
						label: "Seznam lamp",
						icon: <ListIcon />,
						content: (
							<SkeletonLoader
								data={[lampListApiState]}
								render={([lampList]) => (
									<LampList items={lampList} />
								)}
							/>
						),
					}, {
						to: "detail",
						label: "Detail větve",
						icon: <DetailIcon />,
						content: (
							<TabContent title="O větvi veřejného osvětlení">
								<SkeletonLoader
									data={[lampListApiState]}
									render={([lampList]) => (
										<LampBranchCardsWrapper
											lampBranch={lampBranchDetail}
											switchPointId={zapinaciBodId}
											qrCodes={lampList.map(lamp => getQrCodeItemForLamp(lamp, zapinaciBodId, vetevId, activeLau.id))}
											qrSetFileName={"qr_vo_" + normalizeText(lampBranchDetail.oznaceni) + ".pdf"}
										/>
									)}
								/>

							</TabContent>
						),
					},
				]}
				actionLinks={[
					{
						to: "pridat-vetev",
						label: "Přidat lampu",
						icon: <AddIcon />,
						content: (
							<TabContent>
								<LampCreateForm lampBranch={lampBranchDetail} lampBranchId={vetevId}
												switchPointId={zapinaciBodId} onCreate={() => invalidateLampList()} />
							</TabContent>
						),
					}, {
						to: "export-vetev",
						label: "Exportovat",
						icon: <DownloadIcon />,
						content: (
							<DownloadTableTile
								onDownload={() => downloadLampListTable(lampBranchDetail, switchPointDetail)}
								cardTitle={"Seznam lamp veřejného osvětlení"}
								buttonLabel={"Stáhnout tabulku lamp veřejného osvětlení"}
							/>
						),
					},
				]}
			/>
		</Page>
	);
};