import { LampCards } from "./LampCards";
import { LampBranchDto, LampDto } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { FormVersion, useAlert, FormWithCreate } from "@pasport/react-common";
import { FormMode } from "@pasport/react-ui";
import { getAbsoluteRoutePath } from "../../core/routing";
import { createLamp } from "../../apis/lamp";


const defaultLamp: LampDto = {
	id: -1,
	oznaceni: "",
	umisteni: "vlastní konstrukce",
	typSloupu: "betonový",
	vyskaSloupu: 0,
	pocetVylozniku: 0,
	pocetSvitidel: 0,
	spotreba: 0,

	datumPosledniZavady: null,
	datumPosledniUdrzby: null,
	datumPlanovaneRevize: null,

	poznamka: "",
	oznaceniZB: "",
	oznaceniVetve: "",
	castObce: "",
	hash: "",


};

interface LampCreateFormProps {
	lampBranchId: number,
	switchPointId: number,
	onCreate?: () => void,
	lampBranch: LampBranchDto
}

export const LampCreateForm = ({ lampBranchId, switchPointId, onCreate, lampBranch }: LampCreateFormProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithCreate
			initValues={defaultLamp}
			dispatchCreate={(value) => createLamp(value, lampBranchId, switchPointId, lampBranch)}
			onCreateSuccess={(lampId: number) => {
				addSuccessAlert({ message: "Byla přidána nová lampa." });
				navigate(
					getAbsoluteRoutePath("lampa", {
						zapinaciBodId: switchPointId,
						vetevId: lampBranchId,
						lampaId: lampId,
					}),
				);
				if (onCreate) onCreate();
			}}
		>
			{() => <LampCards mode={FormMode.EDIT} version={FormVersion.CREATE} />}
		</FormWithCreate>
	);

};

