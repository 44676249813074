import {
	booleanToApi,
	DiaryEntryDto,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchEntityDetail,
	fetchEntityList,
	fetchPostAction,
	fetchUpdateEntity,
	fetchUploadFile,
	FileDto,
	FileUploadDto,
	getPasportApiUrl,
	mapApiToPhoto,
	mapPositionToRealJTSK,
	numberToApi,
	PositionDto,
} from "@pasport/react-common";
import { LampBranchDto, LampDto } from "../../interfaces";
import { LampSearchApiResponse, LampSearchItemApiDto, MapLampData, MapLampDto } from "./LampApi.dto";
import { mapApiToLamp, mapApiToLampData, mapLampToApi } from "./LampApi.map";
import { mapDiaryEntryToVoApi, mapVOApiToDiaryEntry, mapVOApiToDiaryEntryWithDescriptor } from "../../overrides/diary";
import { mapApiToLight } from "../light/LightApi.map";

//lamp detail

export const getLampDetail = (lampId: number, lampBranchId: number, switchPointId: number) => {
	return fetchEntityDetail(`lampa/detail/${switchPointId}/${lampBranchId}/${lampId}`, mapApiToLamp);
};

export const updateLampDetail = async (
	lamp: LampDto,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchUpdateEntity("updateLampaData", {
		...mapLampToApi(lamp),
		lampa: lamp.id,
		vetev: lampBranchId,
		zapBod: switchPointId,
	}).then(() => updateLampPosition(lamp.pozice, lamp.id, lamp.oznaceniVetve));
};

export const deleteLamp = async (lampId: number, lampBranchId: number, switchPointId: number) => {
	return fetchDeleteEntity("deleteLampa", {
		ZapBod: switchPointId,
		Vetev: lampBranchId,
		Lampa: lampId,
	});
};

export const createLamp = async (
	lamp: LampDto,
	lampBranchId: number,
	switchPointId: number,
	lampBranchDetail: LampBranchDto,
) => {
	return fetchCreateEntity("insertLampa", {
		...mapLampToApi(lamp),
		vetev_id: lampBranchId,
	}).then(async (lampId: number) => {
		if (lamp.pozice)
			await updateLampPosition(lamp.pozice, lampId, lampBranchDetail.oznaceni);
		return lampId;
	});
};

export const getLampPhotoList = async (
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchEntityList(`lampa/images/${switchPointId}/${lampBranchId}/${lampId}`, mapApiToPhoto);
};

export const uploadLampPhoto = async (upload: FileUploadDto, lampId: number) => {
	return fetchUploadFile(upload, {
		Lampa: lampId + "",
		case: "Lampa",
	});
};

export const deleteLampPhoto = async (
	file: FileDto,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchDeleteEntity("deleteFileLampa", {
		attach_id: file.attach_id,
		Lampa: lampId,
		Vetev: lampBranchId,
		ZapBod: switchPointId,
	});
};

export const updateLampPosition = (
	position: PositionDto | undefined,
	lampId: number,
	lampBranchName: string,
) => {
	const pos = position?.x && position?.y ? mapPositionToRealJTSK(position.x, position.y) : { x: null, y: null };
	return fetchUpdateEntity("updateLampaSPI", {
		st_x: numberToApi(pos.x),
		st_y: numberToApi(pos.y),
		lampa: lampId,
		vetev: lampBranchName,
		DXgdb_geomattr_data: "",
		DXkod2: "",
		DXtext1: "",
		DXtext2: "",
	});
};

export const getDiaryEntriesInLamp = async (
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchEntityList(`vohlas/seznam/${switchPointId}/${lampBranchId}/${lampId}`, mapVOApiToDiaryEntry);
};

export const createDiaryEntryForLamp = async (diaryEntry: DiaryEntryDto, lampId: number, lampBranchId: number, switchPointId: number, lauId?: number) => {
	return fetchCreateEntity("insertVoHlaseni", {
			...mapDiaryEntryToVoApi(diaryEntry, true),
			lampa_id: lampId,
			vetev_id: lampBranchId,
			zb_id: switchPointId,
		},
		undefined,
		lauId ? { activeLau: lauId } : undefined,
	);
};

export const updateIsSolvedDiaryEntryForLamp = async (
	diaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
	isSolved: boolean,
) => {
	return fetchUpdateEntity("updateVoHlaseniZaskrtnuti", {
		opraveno: booleanToApi(isSolved),
		denik: diaryEntryId,
		lampa: lampId,
		vetev: lampBranchId,
		zapBod: switchPointId,
	});
};

export const getLightListInLamp = async (
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchEntityList(`svit/seznam/${switchPointId}/${lampBranchId}/${lampId}`, mapApiToLight);
};

//diary entry of lamp

export const getDiaryEntryDetailForLamp = async (
	diaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchEntityDetail(`vohlas/detail/${switchPointId}/${lampBranchId}/${lampId}/${diaryEntryId}`, mapVOApiToDiaryEntry);

};

export const getChildrenOfDiaryEntryListForLamp = async (
	parentDiaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
): Promise<DiaryEntryDto[]> => {
	return fetchEntityList(
		`vohlas/seznamHlaseni/${switchPointId}/${lampBranchId}/${lampId}/${parentDiaryEntryId}`, mapVOApiToDiaryEntry,
	);
};

export const updateDiaryEntryForLamp = async (
	diaryEntry: DiaryEntryDto,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchUpdateEntity("updateVOHlaseni", {
		...mapDiaryEntryToVoApi(diaryEntry, false),
		denikvo_id: diaryEntry.id,
		lampa_id: lampId,
		vetev_id: lampBranchId,
		zapBod: switchPointId,
	});
};

//lampa | hlaseni

export const getDiaryEntryPhotosForLamp = async (
	diaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchEntityList(
		`vohlas/seznamIMG/${switchPointId}/${lampBranchId}/${lampId}/${diaryEntryId}`, mapApiToPhoto,
	);
};

export const uploadLampDiaryEntryPhoto = async (upload: FileUploadDto, diaryEntryId: number) => {
	return fetchUploadFile(upload, {
		denikvo_id: diaryEntryId,
		case: "VOHlaseni",
	});
};

export const deleteLampDiaryEntryPhoto = async (
	file: FileDto,
	diaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchDeleteEntity("deleteFileVOHlaseni", {
		ZapBod: switchPointId,
		denikvo_id: diaryEntryId,
		attach_id: file.attach_id,
		lampa_id: lampId,
		vetev_id: lampBranchId,
	});
};

export const deleteDiaryEntryForLamp = async (
	diaryEntryId: number,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
) => {
	return fetchDeleteEntity("deleteVOHlaseni", {
		denikvo_id: diaryEntryId,
		Lampa: lampId,
		Vetev: lampBranchId,
		zapBod: switchPointId,
	});
};

//overall diaries
export const getAllDiaryEntriesForLamps = async () => {
	return fetchEntityList(`vohlas/seznamHlaseniObce`, mapVOApiToDiaryEntryWithDescriptor);
};


export const getLampsByString = async (searchQuery: string): Promise<LampSearchApiResponse> => {
	return fetchEntityList(`voObce/${searchQuery}`, (data: LampSearchItemApiDto) => (data));
};

// Map
export const getMapLamps = async (lau: number): Promise<MapLampData[]> => {
	return fetchPostAction("mapVoObce", {
		submethod: "map",
		lau: lau,
	}, getPasportApiUrl("fce/select.php"))
		.then(response => response.data)
		.then((data: MapLampDto[]) => data.map(mapApiToLampData));
};
