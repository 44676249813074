import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { SkeletonLoader, TabEmptyContent, useApiState } from "@pasport/react-common";
import { Card, Modal, SearchInput } from "@pasport/react-ui";
import { getAbsoluteRoutePath } from "../../core/routing";
import { getLampsByString } from "../../apis/lamp";
import { LampSearchApiResponse } from "../../apis/lamp/LampApi.dto";

interface LampSearchModalProps {
	visible: boolean,
	onClose: () => void,
}

export const LampSearchModal = ({ visible, onClose }: LampSearchModalProps) => {
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState("");

	const { fetchApiState, apiState } = useApiState<LampSearchApiResponse>();

	useEffect(() => {
		setSearchQuery("");
	}, [visible]);


	useEffect(() => {
		if (searchQuery !== "") {
			fetchApiState(() => getLampsByString(searchQuery));
		} else {
			fetchApiState(() => new Promise((resolve) => resolve([])));
		}
	}, [searchQuery]);

	return (
		<Modal show={visible} onClose={onClose} colorVariant="light" align={"top"}
			   header={(
				   <>
					   <div className="fs-5 fw-bold pb-2">Vyhledat lampu</div>
					   <SearchInput placeholder={"Zadejte označení lampy"}
									onTextChange={(text) => setSearchQuery(text.trim())} autoFocus size="large" />
				   </>
			   )}
		>

			<div className="overflsow-auto">
				<SkeletonLoader
					data={[apiState]}
					render={([lampList]) => (
						<div className={"d-flex flex-column gap-3"}>
							{lampList.length > 0 ? (
								<div>
									<div className="fs-6 p-1 pt-0">Nalezené lampy ({lampList.length})</div>
									<div className={"d-flex flex-column gap-2"}>
										{lampList.map(item => (
											<Card flat onPress={() => navigate(getAbsoluteRoutePath("lampa", {
												zapinaciBodId: item?.zb_id,
												vetevId: item.vetev_id,
												lampaId: item.lampa_id,
											}))}>
												<div
													className={"p-3 fs-6 d-flex align-items-center justify-content-between "}>
													<div>
														<i className="bi bi-arrow-return-right pe-4"></i>
														<strong>{item.oznaceni_zb}</strong>
														<i className="bi bi-chevron-right px-2 "></i>
														<strong>{item.oznaceni_vetve}</strong>
														<i className="bi bi-chevron-right px-2"></i>
														<strong>{item.oznaceni_lampy}</strong>
													</div>
												</div>
											</Card>
										))}
									</div>


								</div>) : (
								<TabEmptyContent text={searchQuery === "" ? "Zadejte přesné označení lampy." : (
									<div>
										Výrazu "<strong>{searchQuery}</strong>" neodpovídá žádná lampa.
									</div>
								)}
								/>
							)}

						</div>
					)}
					renderLoading={() => <TabEmptyContent text={"..."} />}
				/>

			</div>

		</Modal>
	);
};