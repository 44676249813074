import { ContactDto } from "../../interfaces/ContactDto";
import {
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchToJson,
	getCommonApiUrl,
} from "@pasport/react-common";
import { mapApiToContact, mapContactToApi } from "./ContactApi.map";
import { ContactApiDto } from "./ContactApi.dto";

export const getContacts = async () => {
	return fetchToJson(getCommonApiUrl("index.php?pasport=vo&method=email"), false)
		.then(response => response.data)
		.then(response => response.map((item: ContactApiDto): ContactDto => mapApiToContact(item)));
};


export const createContact = async (contact: ContactDto) => {
	return fetchCreateEntity("insertEmail", {
		...mapContactToApi(contact),
		pasport: "vo",
	}, getCommonApiUrl("index.php")).catch(() => 1);
};

export const deleteContact = async (contactId: number) => {
	return fetchDeleteEntity("deleteEmail", {
		kontakt_id: contactId,
		pasport: "vo",
	}, getCommonApiUrl("index.php")).catch(() => 1);
};


