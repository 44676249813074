import { SwitchPointDto } from "../../interfaces";
import { useTownParts } from "../../hooks";
import { useField } from "formik";
import {
	createForm,
	FileDto,
	FileUploadDto,
	FormMode,
	FormVersion,
	OptionItem,
	PhotoListCard,
	PositionCard,
	PositionMapObject,
	QrCodeCard,
} from "@pasport/react-common";
import { DetailCard, DetailColumn, DetailRow } from "@pasport/react-ui";

interface EditTextItemProps {
	label: string;
	value: string;
	mode?: FormMode;
}

const Form = createForm<SwitchPointDto>();

interface SwitchPointCardsProps {
	mode: FormMode;
	version?: FormVersion;
	switchPointId?: number;
	dispatchPhotoUpload?: (upload: FileUploadDto) => Promise<any>;
	dispatchPhotoDelete?: (file: FileDto) => Promise<any>;
	dispatchPhotoList?: () => Promise<any>;
	qrCode?: { url: string; label: string };
}

export const SwitchPointCards = ({
	switchPointId,
	mode,
	version = FormVersion.UPDATE,
	dispatchPhotoUpload,
	qrCode,
	dispatchPhotoDelete,
	dispatchPhotoList,
}: SwitchPointCardsProps) => {
	const { townParts } = useTownParts();
	const [positionField] = useField("pozice");
	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"oznaceni"} label={"Označení"} mode={mode} required />
						<Form.EnumItem
							name={"uzemiId"}
							label={"Část obce"}
							mode={mode}
							required
							options={[
								{
									label: "Vyberte část obce",
									value: null,
								},
								...townParts.map(
									(part) =>
										({
											label: part.name,
											value: part.id,
										}) as unknown as OptionItem
								),
							]}
						/>
						<Form.TextItem name={"umisteni"} label={"Umístění"} mode={mode} />
						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
				<PhotoListCard
					hide={version === FormVersion.CREATE}
					isEditMode={mode === FormMode.EDIT}
					loadImages={dispatchPhotoList}
					dispatchPhotoUpload={dispatchPhotoUpload}
					onPhotoDelete={dispatchPhotoDelete}
				/>
				{qrCode && (
					<QrCodeCard
						value={qrCode.url}
						title={"QR kód zapínacího bodu"}
						label={qrCode.label}
						description={"Nahlášení závady"}
					/>
				)}
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Datumy">
					<Form.DateItem
						name={"datumPosledniZavady"}
						label={"Datum poslední hlášené závady"}
						mode={mode}
						readonly
					/>
					<Form.DateItem
						name={"datumPosledniUdrzby"}
						label={"Datum poslední údržby"}
						mode={mode}
						readonly
					/>
					<Form.DateItem
						name={"datumPlanovaneRevize"}
						label={"Datum plánované revize"}
						mode={mode}
					/>
				</DetailCard>
				<PositionCard
					position={positionField.value}
					object={PositionMapObject.SWITCH_POINT}
					mode={mode}
					version={version}
				/>
			</DetailColumn>
		</DetailRow>
	);
};
