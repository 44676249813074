import { SwitchPointCards } from "./SwitchPointCards";
import { SwitchPointDto } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { FormMode, FormVersion, FormWithCreate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";
import { createSwitchPoint } from "../../apis/switchPoint";

type DefaultSwitchPoint = Omit<SwitchPointDto, "uzemiId"> & { uzemiId: null | number }

const defaultSwitchPoint: DefaultSwitchPoint = {
	id: -1,
	umisteni: "",
	poznamka: "",
	oznaceni: "",
	uzemiId: null,
	datumPosledniUdrzby: null,
	datumPlanovaneRevize: null,
	datumPosledniZavady: null,
	castObce: "",
	hash: "",
};


interface SwitchPointCreateFormProps {
}

export const SwitchPointCreateForm = ({}: SwitchPointCreateFormProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithCreate
			initValues={defaultSwitchPoint}
			dispatchCreate={(value) => createSwitchPoint(value as unknown as SwitchPointDto)}
			onCreateSuccess={(switchPointId: number) => {
				addSuccessAlert({ message: "Byl přidán zapínací bod." });
				navigate(
					getAbsoluteRoutePath("zapinaciBod", {
						zapinaciBodId: switchPointId,
					}),
				);
			}}
		>
			{() => <SwitchPointCards mode={FormMode.EDIT} version={FormVersion.CREATE} />}
		</FormWithCreate>
	);

};

