import {
	Breadcrumbs,
	BreadcrumbSetter,
	LayerSwitchControl,
	LegendLayerLine,
	MapView,
	sortStrings,
} from "@pasport/react-common";
import { CardDivider } from "@pasport/react-ui";
import { useLampsAndSwitchPoints } from "../../hooks/map/useLampsAndSwitchPoints";
import ShowTitlesButton from "./components/ShowTitlesButton";
import { getAbsoluteRoutePath } from "../../core/routing";

export const MapScreen = () => {
	const { state: lampsState, addToMap: addLampsToMap } = useLampsAndSwitchPoints();

	return (
		<div
			className="h-100"
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<BreadcrumbSetter
				to={getAbsoluteRoutePath("mapa", undefined)}
				label={`Mapa veřejného osvětlení v obci`}
			/>
			<Breadcrumbs />
			<div
				style={{
					flex: 1,
				}}
			>
				<MapView apiStates={[lampsState]} dataProcess={[addLampsToMap]}>
					{(map, layers) => (
						<>
							{Object.values(layers.switchPoints)
								.sort((a, b) => sortStrings(a.data.oznaceni, b.data.oznaceni))
								.map((layer) => (
									<>
										<LegendLayerLine
											title={layer.data?.oznaceni}
											layer={layer.layer}
											level={-1}
											render={
												Object.values(layer.branches).length == 0
													? undefined
													: (active) => (
															<>
																{Object.values(layer.branches)
																	.sort((a, b) =>
																		sortStrings(
																			a.data.oznaceni,
																			b.data.oznaceni
																		)
																	)
																	.map((branch) => (
																		<>
																			<LegendLayerLine
																				title={
																					branch.data
																						?.oznaceni
																				}
																				layer={branch.layer}
																				level={0}
																				color={
																					branch.data
																						.color
																				}
																			/>
																		</>
																	))}
															</>
														)
											}
										/>
									</>
								))}

							<div className="m-2" />
							<CardDivider />
							<div className="m-2" />
							<ShowTitlesButton />

							<LayerSwitchControl map={map} />
						</>
					)}
				</MapView>
			</div>
		</div>
	);
};
