import { useEffect, useState } from "react";
import { ContactDto } from "../../interfaces/ContactDto";
import { ContactItem } from "./ContactItem";
import { ContactCreateForm } from "./ContactCreateForm";
import {
	isApiStateDispatched,
	Page,
	PageHeader,
	SkeletonLoader,
	useAlert,
	useApiState,
	Spacing,
} from "@pasport/react-common";
import { Button, Card, CardBody, CardHeader, CardSection, Modal } from "@pasport/react-ui";
import { getContacts } from "../../apis/contact";


export const ManageContactsScreen = () => {
	//the description would be different for each passport
	const [createModal, setCreateModal] = useState(false);
	const { addSuccessAlert } = useAlert();

	const { fetchApiState: fetch, apiState, invalidateApiState } = useApiState<ContactDto[]>();

	useEffect(() => {
		if (!isApiStateDispatched(apiState))
			fetch(() => getContacts());
	}, [apiState]);

	return (
		<Page>
			<PageHeader title={"Spravovat kontakty"} />
			<div className="text-center">Zde můžete spravovat emailové adresy, na které se odesílá upozornění o nové
				závadě.
			</div>
			<Spacing space={3} />
			<div className={"row justify-content-center"}>
				<div className={"col-12 col-md-6"}>
					<Card>
						<CardBody>
							<CardHeader glueBottom>Upozornění zasílat na</CardHeader>
							<CardSection>
								<SkeletonLoader
									data={[apiState]}
									render={([contactList]) => (
										<>
											{contactList.length === 0 ? (
												"Zatím zde nejsou žádné kontaktní údaje."
											) : (
												<div className={"d-flex gap-3 flex-column"}>
													{contactList.map(contact => (
														<ContactItem key={contact.id} contact={contact}
																	 onDeleteSuccess={() => {
																		 invalidateApiState();
																		 addSuccessAlert({ message: "Kontaktní údaj byl odstraněn." });
																	 }} />
													))}
												</div>
											)}
											<Modal header={<span className={"fs-5 fw-bold"}>Přidat nový kontakt</span>}
												   show={createModal}
												   onClose={() => setCreateModal(false)}
											>
												<ContactCreateForm onCreate={() => {
													setCreateModal(false);
													invalidateApiState();
												}} />
											</Modal>
										</>
									)} />
							</CardSection>
							<CardSection>
								<div className="align-items-end justify-content-end d-flex">
									<Button color="success" onPress={() => setCreateModal(true)}>
										Přidat nový kontakt
									</Button>
								</div>
							</CardSection>
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	);
};
