import { useEffect } from "react";
import { DiaryEntryServiceModals } from "./DiaryEntryServiceModals";
import { DiaryEntryDto, isApiStateDispatched, SkeletonLoader, useApiState } from "@pasport/react-common";


interface CreateDiaryEntryAuthorizedProps {
	dispatchCreate: (values: DiaryEntryDto, parentDiary?: DiaryEntryDto) => Promise<number>,
	dispatchDiaryEntries: () => Promise<DiaryEntryDto[]>
	onCreateSuccess: () => void,
	variant?: "lamp" | "switchPoint",
}

export const CreateDiaryEntryAuthorized = ({
											   dispatchCreate,
											   onCreateSuccess,
											   dispatchDiaryEntries,
											   variant,
										   }: CreateDiaryEntryAuthorizedProps) => {
	const { fetchApiState: fetchDiaryEntries, apiState: diaryEntriesApiState } = useApiState<DiaryEntryDto[]>();


	useEffect(() => {
		if (!isApiStateDispatched(diaryEntriesApiState))
			fetchDiaryEntries(dispatchDiaryEntries);
	}, [diaryEntriesApiState]);

	return (
		<>
			<SkeletonLoader
				data={[diaryEntriesApiState]}
				render={([diaryEntryList]) => (
					<DiaryEntryServiceModals
						diaryEntryList={diaryEntryList}
						dispatchCreate={dispatchCreate}
						onCreateSuccess={onCreateSuccess}
						variant={variant}
					/>
				)}
			/>
		</>
	);
};