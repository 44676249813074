import { Link } from "react-router-dom";
import { LightDto } from "../../interfaces/LightDto";
import { ReactNode } from "react";
import { Card, CardDivider, CardSection, Row, RowItem } from "@pasport/react-ui";

interface LightItemProps {
	value: LightDto,
	onMorePress?: () => void
}

interface CardItemValueProps {
	label: ReactNode,
	children: ReactNode,
	unit?: string,
}

const CardItemValue = ({ label, children, unit }: CardItemValueProps) => {
	return (
		<div className="">
			<small className="lh-sm">{label}</small>
			<div className="fw-bold lh-sm"> {/*style={{whiteSpace:"pre"}}*/}
				{children !== null && children !== "" ? (
					<>{children}{unit ? " " + unit : ""}</>
				) : " —"}
			</div>
		</div>
	);
};


interface ListItemContentProps {
	children: ReactNode;
	link?: string,
	icon?: ReactNode;
	title?: string | ReactNode
}

export const ListItemContent = ({ icon, link, children }: ListItemContentProps) => {
	return (
		<div className="p-2 ps-2 d-flex flex-row align-items-center ">
			{icon && (
				<div className="ps-1">
					<div className="align-items-center justify-content-center d-flex flex-column"
						 style={{ width: 60, height: 60 }}>
						{icon}
					</div>
				</div>
			)}
			<div className="flex-grow-1 p-0 g-0 align-items-center my-auto">
				{children}
			</div>
			{link !== undefined && (
				<div className="d-flex align-items-center justify-content-center p-3 bg">
					<Link to={link} className="stretched-link" />
					<i className="bi bi-three-dots-vertical fs-4"></i>
				</div>
			)}
		</div>
	);
};


export const LightCardItem = ({ value, onMorePress }: LightItemProps) => {
	return (
		<div className={"pb-2"}>
			<Card>
				<ListItemContent
					icon={<span className={"fs-4 text-warning"}><i className="bi bi-lightbulb-fill"></i></span>}>
					<Row>
						<RowItem label={"Typ"} size={4} priority={10}>{value.typ}</RowItem>
						<RowItem label={"Světelný zdroj"} size={4} priority={9}>{value.svetelnyZdroj}</RowItem>
						<RowItem label={"Spotřeba"} size={4} priority={8}>{value.spotreba} W</RowItem>
					</Row>
				</ListItemContent>
				<CardDivider />
				<CardSection version="comment">
					<div className="d-flex align-items-center">
						<div className={"w-100"}>
							<div className="small text-muted pe-2">Poznámka</div>
							<div className="">{value.poznamka}</div>
						</div>
						<div className="flex-shrink-1">
							<a href="/" onClick={(e) => {
								e.preventDefault();
								if (onMorePress) onMorePress();
							}}>
								<i className="bi bi-three-dots-vertical fs-4 text-dark"></i>
							</a>
						</div>
					</div>
				</CardSection>
			</Card>
		</div>
	);

	return (
		<div className={"p-2"}>
			<Card version="light">
				<CardSection>
					<div className={"d-flex justify-content-between"}>
						<span className={"fs-4 text-warning"}><i className="bi bi-lightbulb-fill"></i></span>
						<CardItemValue label={"Typ"}>{value.typ}</CardItemValue>
						<CardItemValue label={"Světelný zdroj"}>{value.svetelnyZdroj}</CardItemValue>
						<CardItemValue label={"Spotřeba"}>{value.spotreba} W</CardItemValue>
					</div>
				</CardSection>
				<CardDivider />
				<CardSection>
					<div className={"d-flex justify-content-between"}>
						<CardItemValue label={"Poznámka"}>{value.poznamka}</CardItemValue>
					</div>
				</CardSection>
			</Card>
		</div>
	);

	//
	// return (
	//     <ListItem link={"../svitidlo/"+value.id} icon={<span className={"fs-4 text-warning"}><i className="bi bi-lightbulb-fill"></i></span>}>
	//         <Row>
	//             <RowItem label={"Typ"} size={3}>{value.typ}</RowItem>
	//             <RowItem label={"Světelný zdroj"} size={3}>{value.svetelnyZdroj}</RowItem>
	//             <RowItem label={"Spotřeba"} size={3}>{value.spotreba} W</RowItem>
	//             <RowItem label={"Poznámka"} size={3}>{value.poznamka}</RowItem>
	//         </Row>
	//     </ListItem>
	// );
};