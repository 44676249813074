import { Route, Routes } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { MapScreen } from "../screens/MapScreen/MapScreen";
import { RouterPaths } from "../core/routing/routerPaths";
import { CreateLampDiaryEntryScreen } from "../screens/DiaryEntryQrPointScreen/CreateLampDiaryEntryScreen";
import {
	CreateSwitchPointDiaryEntryScreen,
} from "../screens/DiaryEntryQrPointScreen/CreateSwitchPointDiaryEntryScreen";
import { AllDiaryEntryListScreen } from "../screens/AllDiaryEntryListScreen";
import React from "react";

import { LoginCallbackScreen, Protected, SelectLauScreen } from "@pasport/react-common";
import { ManageContactsScreen } from "../common/screens";

export const BaseRouter = () => {
	return (
		<Routes>
			<Route
				path="*"
				element={
					<Protected>
						<AppRouter />
					</Protected>
				}
			/>

			<Route
				path={"mapa"}
				element={
					<Protected>
						<MapScreen />
					</Protected>
				}
			/>

			<Route
				path={`/${RouterPaths.vsechnaHlaseni}/*`}
				element={
					<Protected>
						<AllDiaryEntryListScreen />
					</Protected>
				}
			/>

			<Route
				path={RouterPaths.spravovatUdaje}
				element={
					<Protected>
						<ManageContactsScreen />
					</Protected>
				}
			/>

			<Route
				path={"vyber-lau"}
				element={
					<Protected>
						<SelectLauScreen />
					</Protected>
				}
			/>

			<Route
				path={`/${RouterPaths.pridatLampaHlaseni}`}
				element={<CreateLampDiaryEntryScreen />}
			/>
			<Route
				path={`/${RouterPaths.pridatZapinaciBodHlaseni}`}
				element={<CreateSwitchPointDiaryEntryScreen />}
			/>

			<Route path={"login"} element={<LoginCallbackScreen />} />
		</Routes>
	);
};
