import { useState } from "react";
import { Button, Card, CardBody, CardHeader, CardSection } from "@pasport/react-ui";
import { DiaryEntryCreateForm, DiaryEntryDto, DiaryEntryType, DiaryItem } from "@pasport/react-common";
import { DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS } from "./CreateDiaryEntryAnonymous";


interface DiaryEntryServiceModalsProps {
	diaryEntryList: DiaryEntryDto[],

	dispatchCreate: (values: DiaryEntryDto, parentDiary?: DiaryEntryDto) => Promise<number>,
	onCreateSuccess: () => void,
	variant?: "lamp" | "switchPoint",
}

enum CreateServiceDiaryEntryState {
	HAS_PARENT_DIARY = "HAS_PARENT_DIARY",
	SELECT_PARENT_DIARY = "SELECT_PARENT_DIARY",
	IS_ISSUE_SOLVED = "IS_ISSUE_SOLVED",
	DIARY_FORM = "DIARY_FORM"

}

export const DiaryEntryServiceModals = ({
											diaryEntryList,
											dispatchCreate,
											onCreateSuccess,
											variant,
										}: DiaryEntryServiceModalsProps) => {
	const [formState, setFormState] = useState<CreateServiceDiaryEntryState>(
		diaryEntryList.length !== 0 ? CreateServiceDiaryEntryState.HAS_PARENT_DIARY : CreateServiceDiaryEntryState.DIARY_FORM,
	);

	const [hasDiaryParent, setHasDiaryParent] = useState<boolean | null>(null);
	const [parentDiary, setParentDiary] = useState<DiaryEntryDto | null>(null);
	const [isIssueSolved, setIsIssueSolved] = useState<boolean | null>(null);

	const handleHasDiaryParent = (hasParent: boolean) => {
		setHasDiaryParent(hasParent);
		if (hasParent) {
			setFormState(CreateServiceDiaryEntryState.SELECT_PARENT_DIARY);
		} else {
			setFormState(CreateServiceDiaryEntryState.DIARY_FORM);
		}

	};

	const handleSelectDiaryParent = (diaryEntry: DiaryEntryDto) => {
		setParentDiary(diaryEntry);
		setFormState(CreateServiceDiaryEntryState.IS_ISSUE_SOLVED);
	};

	const handleIsIssueSolved = (isSolved: boolean) => {
		setIsIssueSolved(isSolved);
		setFormState(CreateServiceDiaryEntryState.DIARY_FORM);
	};

	return (
		<>
			{formState === CreateServiceDiaryEntryState.HAS_PARENT_DIARY && (
				<div className="d-flex align-items-center justify-content-center">
					<Card>
						<CardBody>
							<CardHeader glueBottom>Reagujete na nahlášenou závadu?</CardHeader>
							<CardSection>
								<div className="d-flex justify-content-end gap-3">
									<Button color="success" fullWidth
											onPress={() => handleHasDiaryParent(true)}>Ano</Button>
									<Button color="dark" fullWidth
											onPress={() => handleHasDiaryParent(false)}>Ne</Button>
								</div>
							</CardSection>
						</CardBody>
					</Card>
				</div>
			)}
			{formState === CreateServiceDiaryEntryState.SELECT_PARENT_DIARY && (
				<div className="d-flex align-items-center justify-content-center">
					<Card>
						<CardBody>
							<CardHeader glueBottom>Vyberte závadu, na kterou reagujete</CardHeader>
							<CardSection>
								<div className="d-flex flex-column " style={{ maxHeight: "100%" }}>
									{diaryEntryList.filter(item => (item.typ === DiaryEntryType.ZAVADA && !item.parentId)).map(item => (
										<div key={item.id} className="pb-2">
											<DiaryItem small value={item}
													   onPress={() => handleSelectDiaryParent(item)} />
										</div>
									))}
								</div>
							</CardSection>
						</CardBody>
					</Card>
				</div>
			)}

			{formState === CreateServiceDiaryEntryState.IS_ISSUE_SOLVED && (
				<div className="d-flex align-items-center justify-content-center flex-column">
					<Card>
						<CardBody>
							<CardHeader glueBottom>Je tato závada vyřešená?</CardHeader>
							<CardSection>
								<div className="d-flex justify-content-end gap-3">
									<Button color="success" fullWidth
											onPress={() => handleIsIssueSolved(true)}>Ano</Button>
									<Button color="danger" fullWidth
											onPress={() => handleIsIssueSolved(false)}>Ne</Button>
								</div>
							</CardSection>
						</CardBody>
					</Card>
				</div>
			)}
			{formState === CreateServiceDiaryEntryState.DIARY_FORM && (
				<div className={"row justify-content-center"}>
					<div className={"col-12 col-md-6"}>
						<DiaryEntryCreateForm
							mainCardTitle={"Vyplňte potřebné informace"}
							dispatchCreate={(formValues) => dispatchCreate(
								{ ...formValues, parentId: parentDiary?.id },
								parentDiary && isIssueSolved !== null ? {
									...parentDiary,
									opraveno: isIssueSolved,
								} : undefined,
							)}
							onCreateSuccess={onCreateSuccess}
							textSuggestions={variant === "lamp" ? DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS : []}
							submitButtonVersion="bottom"
							defaultValues={parentDiary ? { typ: DiaryEntryType.SERVIS } : {}}
							fullSize
						/>
					</div>
				</div>

			)}


		</>
	);
};