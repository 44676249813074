import { LightCardItem } from "../Light";
import { LightDto } from "../../interfaces/LightDto";
import { useState } from "react";
import { LightCards } from "../Light/LightCards";
import { useNavigate } from "react-router-dom";
import { ApiState, useAlert, SkeletonLoader, FormMode, FormWithUpdate } from "@pasport/react-common";
import { CardHeader, DetailCard, Modal } from "@pasport/react-ui";
import { deleteLight, updateLightDetail } from "../../apis/light";

interface LampLightCardProps {
	lightListApiState: ApiState<LightDto[]>,
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
	onUpdate?: () => void
}

export const LampLightCard = ({
								  lightListApiState,
								  lampId,
								  lampBranchId,
								  switchPointId,
								  onUpdate,
							  }: LampLightCardProps) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [activeLight, setActiveLight] = useState<LightDto | null>(null);
	const { addSuccessAlert } = useAlert();
	const navigate = useNavigate();
	return (
		<>
			<SkeletonLoader
				data={[lightListApiState]}
				render={([lightList]) => (
					<DetailCard title="Seznam svítidel">
						<div className="d-flex flex-wrap pb-3">
							{lightList.length > 0 ? lightList.map(light => (
								<div className={"col-12"}>
									<LightCardItem value={light} onMorePress={() => {
										setActiveLight(light);
										setIsModalVisible(true);
									}} />


								</div>
							)) : (<div>Zatím zde není žádné svítidlo.</div>)}
						</div>
					</DetailCard>
				)}
			/>
			<Modal autoWidth={true} show={isModalVisible} onClose={() => setIsModalVisible(false)}>
				<>
					{activeLight && (
						<>
							<CardHeader>Upravit svítidlo</CardHeader>

							<FormWithUpdate
								defaultFormMode={FormMode.EDIT}
								initValues={activeLight}
								dispatchUpdate={(value) => {
									return updateLightDetail(value, lampId, lampBranchId, switchPointId);
								}}
								onUpdateSuccess={() => {
									addSuccessAlert({ message: "Svítidlo bylo upraveno" });
									setIsModalVisible(false);
									if (onUpdate) onUpdate();
								}}

								dispatchDelete={() => deleteLight(activeLight.id, lampId, lampBranchId, switchPointId)}
								onDeleteSuccess={() => {
									addSuccessAlert({ message: "Svitidlo bylo odstraněno" });
									setIsModalVisible(false);
									if (onUpdate) onUpdate();
								}}
								onResetPress={() => setIsModalVisible(false)}
							>
								{({ mode }) => <LightCards mode={FormMode.VIEW} fullScreen />}
							</FormWithUpdate>
						</>
					)}
				</>
			</Modal>


		</>
	);
};