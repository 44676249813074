import { useNavigate } from "react-router-dom";
import React from "react";
import { DiaryParametricScreen, useAlert, useBreadcrumbs } from "@pasport/react-common";
import { LampDto } from "../interfaces";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import {
	createDiaryEntryForLamp, deleteDiaryEntryForLamp,
	deleteLampDiaryEntryPhoto, getChildrenOfDiaryEntryListForLamp,
	getDiaryEntryDetailForLamp,
	getDiaryEntryPhotosForLamp, updateDiaryEntryForLamp, uploadLampDiaryEntryPhoto,
} from "../apis/lamp";


interface LampDiaryScreenProps {
	lampDetail: LampDto;
}

export const LampDiaryScreen = ({ lampDetail }: LampDiaryScreenProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	const { lampaHlaseniId, lampaId, vetevId, zapinaciBodId } = useUrlParams();

	useBreadcrumbs({
		to: getAbsoluteRoutePath("lampaHlaseni", { lampaHlaseniId, lampaId, vetevId, zapinaciBodId }),
		label: "Deníkové hlášení",
	});

	return <DiaryParametricScreen
		identifier={lampaHlaseniId}
		title={`Deníkové hlášení lampy ${lampDetail.oznaceni}`}
		getLink={(diaryEntry) => getAbsoluteRoutePath("lampaHlaseni", {
			zapinaciBodId: zapinaciBodId,
			vetevId: vetevId,
			lampaId: lampaId,
			lampaHlaseniId: diaryEntry.id,
		})}

		dispatchDetail={() => getDiaryEntryDetailForLamp(lampaHlaseniId, lampaId, vetevId, zapinaciBodId)}
		dispatchChildren={() => getChildrenOfDiaryEntryListForLamp(lampaHlaseniId, lampaId, vetevId, zapinaciBodId)}

		dispatchUpdate={(value) => {
			return updateDiaryEntryForLamp(value, lampaId, vetevId, zapinaciBodId);
		}}
		onUpdateSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení lampy bylo upraveno." });
		}}

		dispatchDelete={() => deleteDiaryEntryForLamp(lampaHlaseniId, lampaId, vetevId, zapinaciBodId)}
		onDeleteSuccess={() => {
			addSuccessAlert({ message: "Deníkové hlášení lampy bylo odstraněno." });
			navigate(
				getAbsoluteRoutePath("lampa", {
					lampaId, vetevId, zapinaciBodId,
				}),
			);
		}}

		dispatchCreate={(formValues) => createDiaryEntryForLamp(
			{ ...formValues, parentId: lampaHlaseniId }, lampaId, vetevId, zapinaciBodId,
		)}
		onCreateSuccess={(diaryEntryId: number) => {
			addSuccessAlert({ message: "K závadě bylo přidáno hlášení!" });
			navigate(
				getAbsoluteRoutePath("lampaHlaseni", {
					zapinaciBodId,
					vetevId,
					lampaId,
					lampaHlaseniId,
				}) + "/reakce",
			);
		}}

		dispatchPhotoList={() => getDiaryEntryPhotosForLamp(lampaHlaseniId, lampaId, vetevId, zapinaciBodId)}
		dispatchPhotoUpload={(upload) => uploadLampDiaryEntryPhoto(upload, lampaHlaseniId)}
		dispatchPhotoDelete={(file) => deleteLampDiaryEntryPhoto(file, lampaHlaseniId, lampaId, vetevId, zapinaciBodId)}
	/>;
};