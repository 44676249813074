import { LampBranchDto } from "../../interfaces";
import { LampBranchCards } from "./LampBranchCards";
import { deleteLampBranch, updateLampBranchDetail } from "../../apis/lampBranch/LampBranchApis";
import { useNavigate } from "react-router-dom";
import { QrCodeItem, useAlert, FormWithUpdate } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";


interface LampBranchCardsWrapperProps {
	lampBranch: LampBranchDto,
	switchPointId: number,
	qrCodes: QrCodeItem[],
	qrSetFileName: string,
}

export const LampBranchCardsWrapper = ({
										   lampBranch,
										   switchPointId,
										   qrCodes,
										   qrSetFileName,
									   }: LampBranchCardsWrapperProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithUpdate
			initValues={lampBranch}
			dispatchUpdate={(value) => {
				console.log("update for ", value);
				return updateLampBranchDetail(value, switchPointId);
			}}
			onUpdateSuccess={() => addSuccessAlert({ message: "Větev veřejného osvětlení byla upravena." })}

			dispatchDelete={() => deleteLampBranch(lampBranch.id, switchPointId)}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Větev byla odstraněna" });
				navigate(
					getAbsoluteRoutePath("zapinaciBod", {
						zapinaciBodId: switchPointId,
					}),
				);
			}}
		>
			{({ mode }) => <LampBranchCards mode={mode} qrCodes={qrCodes} qrSetFileName={qrSetFileName} />}
		</FormWithUpdate>
	);

};

