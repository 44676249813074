 export const LAMP_COLUMN_TYPES = [
    "betonový", "kovový", "dřevěný","příhradový","žádný"
];
export type LampColumnType = typeof LAMP_COLUMN_TYPES[number];

export interface LampDto {
    id: number,
    oznaceni: string,
    umisteni: string,
    typSloupu: LampColumnType | null,
    vyskaSloupu: number | null,
    pocetSvitidel: number,
    pocetVylozniku: number,
    spotreba: number,

    datumPosledniZavady: Date | null,
    datumPosledniUdrzby: Date | null,
    datumPlanovaneRevize: Date | null,


    poznamka: string,

    oznaceniVetve: string,
    oznaceniZB: string,
    castObce: string,

    pozice?: {
        x: number,
        y: number
    },
    hash: string


}