import { Route, Routes } from "react-router-dom";
import { SwitchPointScreen } from "../screens/SwitchPointScreen";
import { SwitchPointDiaryScreen } from "../screens/SwitchPointDiaryScreen";
import React, { useEffect } from "react";
import { SwitchPointDto } from "../interfaces";
import { LampBranchRouter } from "./LampBranchRouter";

import { BreadcrumbSetter, isApiStateDispatched, SkeletonLoader, useApiState } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { getSwitchPointDetail } from "../apis/switchPoint";


export const SwitchPointRouter = () => {

	const { zapinaciBodId } = useUrlParams();

	const { fetchApiState: fetchDetail, apiState: detailApiState } = useApiState<SwitchPointDto>();

	useEffect(() => {
		if (!isApiStateDispatched(detailApiState)) {
			fetchDetail(() => getSwitchPointDetail(zapinaciBodId));
		}
	}, [detailApiState]);

	return (

		<SkeletonLoader
			data={[detailApiState]}
			render={([switchPointDetail]) => (
				<>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("zapinaciBod", { zapinaciBodId })}
						label={`Zapínací bod ${switchPointDetail.oznaceni}`}
					/>
					<Routes>
						<Route path={"*"} element={
							<SwitchPointScreen />
						} />
						<Route path={"hlaseni/:zbHlaseniId/*"}
							   element={<SwitchPointDiaryScreen switchPointDetail={switchPointDetail} />} />
						<Route path={"vetev/:vetevId/*"} element={
							<LampBranchRouter switchPointDetail={switchPointDetail} />
						} />
					</Routes>
				</>
			)}
		/>
	);
};