import { ListItem, Row, RowItem } from "@pasport/react-ui";
import { LampBranchDto } from "../../interfaces/LampBranchDto";

interface LampBranchItemProps {
	value: LampBranchDto;
}

export const LampBranchItem = ({ value }: LampBranchItemProps) => {
	return (
		<ListItem link={"../vetev/" + value.id}
				  icon={<span className={"fs-2 text-success"}><i className="bi bi-diagram-3-fill"></i></span>}>
			<Row>
				<RowItem label={"Větev"} size={3} priority={10}>{value.oznaceni}</RowItem>
				<RowItem label={"Umístění kabelů"} size={2}>{value.umisteniKabelu}</RowItem>
				<RowItem label={"Počet svítidel"} size={2}>{value.pocetSvitidel}</RowItem>
				<RowItem label={"Spotřeba celkem"} size={2}>{value.celkovaSpotreba} W</RowItem>
				<RowItem label={"Poznámka"} size={3} priority={9}>{value.poznamka}</RowItem>
			</Row>
		</ListItem>
	);
};