import { AddIcon, DetailIcon, DiaryIcon, ListIcon } from "@pasport/react-ui";

import {
	DiaryEntryCreateForm,
	DiaryEntryDto,
	DiaryList,
	isApiStateDispatched,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useActiveLau,
	useAlert,
	useApiState,
} from "@pasport/react-common";
import { LampBranchDto, SwitchPointDto } from "../interfaces";
import { SwitchPointCardsWrapper } from "../components/SwitchPoint";
import { LampBranchCreateForm, LampBranchList } from "../components/LampBranch";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import {
	createDiaryEntryForSwitchPoint,
	getDiaryEntryListInSwitchPoint,
	getLampBranchListInSwitchPoint, getSwitchPointDetail,
} from "../apis/switchPoint";


export const SwitchPointScreen = () => {
	const { activeLau } = useActiveLau();
	const navigate = useNavigate();
	const { zapinaciBodId } = useUrlParams();


	const {
		fetchApiState: fetchDetail,
		apiState: detailApiState,
		invalidateApiState: invalidateDetail,
	} = useApiState<SwitchPointDto>();

	const {
		fetchApiState: fetchDiaryEntryList,
		apiState: diaryEntryListApiState,
		invalidateApiState: invalidateDiaryApiState,
	} = useApiState<DiaryEntryDto[]>();

	const {
		fetchApiState: fetchLampBranchList,
		apiState: lampBranchListApiState,
		invalidateApiState: invalidateBranchListApiState,
	} = useApiState<LampBranchDto[]>();

	useEffect(() => {
		if (!isApiStateDispatched(diaryEntryListApiState)) {
			fetchDiaryEntryList(() => getDiaryEntryListInSwitchPoint(zapinaciBodId));
		}
	}, [diaryEntryListApiState]);

	useEffect(() => {
		if (!isApiStateDispatched(lampBranchListApiState)) {
			fetchLampBranchList(() => getLampBranchListInSwitchPoint(zapinaciBodId));
		}
	}, [lampBranchListApiState]);


	useEffect(() => {
		if (!isApiStateDispatched(detailApiState)) {
			fetchDetail(() => getSwitchPointDetail(zapinaciBodId));
		}
	}, [detailApiState]);

	const { addSuccessAlert } = useAlert();


	return (
		<Page>

			<SkeletonLoader
				data={[detailApiState]}
				render={([switchPointDetail]) => {
					const qrCodeUrl = activeLau ? window.location.origin + getAbsoluteRoutePath("pridatZapinaciBodHlaseni", {
						zapinaciBodId,
						lau: activeLau.id,
						hash: switchPointDetail.hash ?? "",
					}) : undefined;
					return (
						<>
							<PageHeader title={"Zapínací bod " + switchPointDetail.oznaceni} />
							<TabRoutes
								links={[
									{
										to: "vetve",
										label: "Seznam větví",
										icon: <ListIcon />,
										content: (
											<SkeletonLoader
												data={[lampBranchListApiState]}
												render={([lampBranchItems]) => (
													<LampBranchList items={lampBranchItems} />
												)}
											/>
										),
									}, {
										to: "detail",
										label: "Detail zapínacího bodu",
										icon: <DetailIcon />,
										content: (
											<TabContent title="O zapínacím bodě">
												<SwitchPointCardsWrapper
													switchPoint={switchPointDetail}
													qrCodeUrl={qrCodeUrl}
													onUpdate={() => {
														invalidateDetail();
													}}
												/>
											</TabContent>
										),
									}, {
										to: "denik",
										label: "Deník zapínacího bodu",
										icon: <DiaryIcon />,
										content: (
											<SkeletonLoader
												data={[diaryEntryListApiState]}
												render={([diaryEntries]) => (
													<DiaryList items={diaryEntries} />
												)}
											/>
										),
									},
								]}
								actionLinks={[
									{
										to: "pridat-vetev",
										label: "Přidat větev",
										icon: <AddIcon />,
										content: (
											<TabContent>
												<LampBranchCreateForm
													switchPointId={zapinaciBodId}
													onCreate={() => invalidateBranchListApiState()}
												/>
											</TabContent>
										),
									}, {
										to: "pridat-denikovy-zaznam",
										label: "Přidat deníkový záznam",
										icon: <AddIcon />,
										content: (
											<TabContent title={"Přidejte deníkový záznam k zapínacímu bodu"}>
												<DiaryEntryCreateForm
													dispatchCreate={(values) => createDiaryEntryForSwitchPoint(values, zapinaciBodId)}
													onCreateSuccess={(diaryEntryId: number) => {
														addSuccessAlert({ message: "Bylo přidáno deníkové hlášení." });
														navigate(
															getAbsoluteRoutePath("zapinaciBodHlaseni", {
																zapinaciBodId,
																zbHlaseniId: diaryEntryId,
															}),
														);
														invalidateDiaryApiState();
													}}
												/>
											</TabContent>
										),
									},
								]}
							/>
						</>
					);
				}}
			/>

		</Page>
	);
};