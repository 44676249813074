import { configureStore } from "@reduxjs/toolkit";
import { townPartReducer } from "./slices/townPartSlice";


export const store = configureStore({
	reducer: {
		townPart: townPartReducer,

	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;