import { SwitchPointDto } from "../../interfaces";
import { SwitchPointItem } from "./SwitchPointItem";
import { SWITCH_POINT_ORDER, SwitchPointListHeader } from "./SwitchPointListHeader";
import { useState } from "react";
import {
	getFulltextSearch,
	SORT_DIRECTION,
	sortDates,
	sortStrings,
	TabEmptyContent,
	useAuth,
} from "@pasport/react-common";

interface SwitchPointListProps {
	items: SwitchPointDto[];
}

function getSwitchPointSortingFunc(key: SWITCH_POINT_ORDER, increasingOrder: boolean): (a: SwitchPointDto, b: SwitchPointDto) => number {
	switch (key) {
		case SWITCH_POINT_ORDER.CAST_OBCE:
			return (a, b) => sortStrings(a.castObce, b.castObce, increasingOrder);
		case SWITCH_POINT_ORDER.DATUM_POSL_ZAVADY:
			return (a, b) => sortDates(a.datumPosledniZavady, b.datumPosledniZavady, increasingOrder);
		case SWITCH_POINT_ORDER.DATUM_POSL_UDRZBY:
			return (a, b) => sortDates(a.datumPosledniUdrzby, b.datumPosledniUdrzby, increasingOrder);
		case SWITCH_POINT_ORDER.DATUM_PLANOVANE_REVIZE:
			return (a, b) => sortDates(a.datumPlanovaneRevize, b.datumPlanovaneRevize, increasingOrder);
		case SWITCH_POINT_ORDER.OZNACENI:
		default:
			return (a, b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder);
	}
}

const sortBySortMethod = (sortMethod: SWITCH_POINT_ORDER, items: SwitchPointDto[], sortDirection: SORT_DIRECTION) => {
	const sortingFunc = getSwitchPointSortingFunc(sortMethod, sortDirection === SORT_DIRECTION.UP);
	return items.slice().sort((a, b) => sortingFunc(a, b));
};

export const SwitchPointList = ({ items }: SwitchPointListProps) => {
	const { activeLau } = useAuth();
	const [searchQuery, setSearchQuery] = useState("");
	const [sortMethod, setSortMethod] = useState<SWITCH_POINT_ORDER>(SWITCH_POINT_ORDER.OZNACENI);
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);

	const itemsFulltext = getFulltextSearch(searchQuery, items, (item) => [item.oznaceni]);
	const itemsSorted = sortBySortMethod(sortMethod, itemsFulltext, sortDirection);

	return items.length > 0 ? (
		<div className={"row"}>
			<SwitchPointListHeader onTextChange={setSearchQuery} setSorting={setSortMethod}
								   setSortDirection={setSortDirection} />
			{itemsSorted.length > 0 ? itemsSorted.map((item) => (
				<div key={item.id} className="col-xl-4 col-md-6 col-12 pb-3">
					<SwitchPointItem value={item} />
				</div>
			)) : "Filtrům neodpovídá žádný zapínací bod"}
		</div>
	) : <TabEmptyContent
		text={<div>Obec <strong className={"text-muted"}>{activeLau?.obec}</strong> zatím nemá přidané žádné zapínací
			body.</div>} />;


};
