import {SelectOption, SearchInput, Select} from "@pasport/react-ui";
import {    SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS, useWindowSize,  } from "@pasport/react-common";

export enum LAMP_BRANCH_SORT_METHOD {
    OZNACENI = 'OZNACENI',
    POCET_SVITIDEL = 'POCET_SVITIDEL',
    SPOTREBA = 'SPOTREBA',
}

const LAMP_BRANCH_SORT_OPTIONS : SelectOption[] = [
    {value: LAMP_BRANCH_SORT_METHOD.OZNACENI, label: "Označení"},
    {value: LAMP_BRANCH_SORT_METHOD.POCET_SVITIDEL, label: "Počet svítidel"},
    {value: LAMP_BRANCH_SORT_METHOD.SPOTREBA, label: "Spotřeba wattů"},
]

interface LampBranchListHeaderProps {
    onTextChange: (searchQuery: string) => void,
    setSortMethod: (sortMethod: LAMP_BRANCH_SORT_METHOD) => void,
    setSortDirection: (sortMethod: SORT_DIRECTION) => void,
}

export const LampBranchListHeader = ({onTextChange, setSortMethod, setSortDirection}: LampBranchListHeaderProps) => {

    const {isMobile} = useWindowSize();

    const handleSortMethodChange = (value: string) => {
        setSortMethod(value as LAMP_BRANCH_SORT_METHOD)
    }

    const handleSortDirection = (value: string) => {
        setSortDirection(value as SORT_DIRECTION);
    }


    return (
        <div className="d-flex align-items-end justify-content-between mb-2 flex-wrap gap-2">
            <div className="d-flex align-items-center gap-3 flex-grow-1">
                <div style={{width: '100%',maxWidth: isMobile ? '100%' : 300}}>
                    <SearchInput placeholder={"Hledat větev dle označení"} onTextChange={onTextChange} />
                </div>
            </div>


            <div className="d-flex gap-2 align-items-center ms-auto">
                <span className="text-nowrap">Seřadit podle:</span>
                <div style={{width: '100%', maxWidth: 300}}>
                    <Select
                        onChange={handleSortMethodChange}
                        options={LAMP_BRANCH_SORT_OPTIONS}
                    />
                </div>
                <div style={{width: '100%', maxWidth: 130}}>
                    <Select
                        onChange={handleSortDirection}
                        options={SORT_DIRECTION_SELECT_OPTIONS}
                    />
                </div>
            </div>

        </div>
    )
}