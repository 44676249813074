import { DiaryListHeader, SEARCH_SELECT_OPTION } from "./DiaryListHeader";
import {
	DIARY_ENTRY_SORT_METHOD,
	DiaryEntryDto,
	getFulltextSearch,
	SORT_DIRECTION,
	sortDates,
	TabContent,
	DiaryItem,
	TabEmptyContent,
} from "@pasport/react-common";
import { useState } from "react";
import { getAbsoluteRoutePath } from "../../core/routing";
import { DiaryEntryWithVoDescriptorDto } from "../../overrides/diary";

interface DiaryListAdvancedProps {
	items: DiaryEntryWithVoDescriptorDto[];
	variant: "lamp" | "switchPoint";
}

function getSortingFunc(
	key: DIARY_ENTRY_SORT_METHOD,
	increasingOrder: boolean
): (a: DiaryEntryDto, b: DiaryEntryDto) => number {
	return (a, b) => sortDates(a.datum, b.datum, increasingOrder);
}

const sortBySortMethod = (
	sortMethod: DIARY_ENTRY_SORT_METHOD,
	items: DiaryEntryWithVoDescriptorDto[],
	sortDirection: SORT_DIRECTION
) => {
	const sortingFunc = getSortingFunc(sortMethod, sortDirection === SORT_DIRECTION.UP);
	return items.slice().sort((a, b) => sortingFunc(a.value, b.value));
};

const getFulltextQueryList = (
	searchType: SEARCH_SELECT_OPTION,
	descriptor: DiaryEntryWithVoDescriptorDto,
	variant: "lamp" | "switchPoint"
): string[] => {
	const AUTHOR_LIST = [
		descriptor.value.provedl,
		descriptor.value.email,
		descriptor.value.uzivatel,
		descriptor.value.telefon,
	];
	switch (searchType) {
		case SEARCH_SELECT_OPTION.LAMP:
			if (variant === "lamp") return [descriptor.detail.oznaceniLampy || ""];
			break;
		case SEARCH_SELECT_OPTION.SWITCH_POINT:
			return [descriptor.detail.oznaceniZB || ""];
		case SEARCH_SELECT_OPTION.AUTHOR:
			return AUTHOR_LIST;
	}
	return [
		...AUTHOR_LIST,
		descriptor.detail.oznaceniLampy || "",
		descriptor.detail.oznaceniZB || "",
	];
};

export const DiaryListAdvanced = ({ items, variant }: DiaryListAdvancedProps) => {
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.DOWN);
	const [searchQuery, setSearchQuery] = useState("");
	const [typeFilter, setTypeFilter] = useState("");

	const [searchQueryType, setSearchQueryType] = useState(SEARCH_SELECT_OPTION.SMART);

	const filtered =
		typeFilter === "all" ? items : items.filter(({ value: item }) => item.typ === typeFilter);

	const stringFilter = getFulltextSearch(searchQuery, filtered, (item) =>
		getFulltextQueryList(searchQueryType, item, variant)
	);
	const itemsSorted = sortBySortMethod(
		DIARY_ENTRY_SORT_METHOD.DATUM,
		stringFilter,
		sortDirection
	);

	return items.length > 0 ? (
		<TabContent title={"Seznam hlášení"}>
			<DiaryListHeader
				onTextChange={setSearchQuery}
				activeEntryType={typeFilter}
				onActiveEntryTypeChange={(type) => setTypeFilter(type)}
				setSortDirection={setSortDirection}
				sortDirection={sortDirection}
				activeSearchType={searchQueryType}
				onActiveSearchTypeChange={(value) =>
					setSearchQueryType(value as SEARCH_SELECT_OPTION)
				}
				variant={variant}
			/>
			{itemsSorted.length > 0
				? itemsSorted.map((item) => {
						const isLamp = variant === "lamp";
						const label = isLamp ? (
							<>
								<strong>{item.detail.oznaceniZB}</strong> / Lampa{" "}
								<strong>{item.detail.oznaceniLampy}</strong>
							</>
						) : (
							<>
								<strong>{item.detail.oznaceniZB}</strong>
							</>
						);
						const link = isLamp
							? getAbsoluteRoutePath("lampaHlaseni", {
									zapinaciBodId: item.detail.zbId,
									lampaId: item.detail.lampaId || 0,
									vetevId: item.detail.vetevId || 0,
									lampaHlaseniId: item.value.id,
								})
							: getAbsoluteRoutePath("zapinaciBodHlaseni", {
									zapinaciBodId: item.detail.zbId,
									zbHlaseniId: item.value.id,
								});
						return (
							<div key={item.value.id} className="pb-2">
								<span className="fw-bosld text-muted small ps-1">{label}</span>
								<DiaryItem value={item.value} link={link} />
							</div>
						);
					})
				: "Filtrům neodpovídá žádné deníkové hlášení"}
		</TabContent>
	) : (
		<TabEmptyContent text={"Zatím zde není žádné deníkové hlášení"} />
	);
};
