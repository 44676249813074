import { Route, Routes } from "react-router-dom";
import { LampBranchScreen } from "../screens/LampBranchScreen";
import React, { useEffect } from "react";
import { getLampBranchDetail } from "../apis/lampBranch/LampBranchApis";
import { LampBranchDto, SwitchPointDto } from "../interfaces";
import { LampRouter } from "./LampRouter";
import { BreadcrumbSetter, isApiStateDispatched, SkeletonLoader, useApiState } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";


type LampBranchRouterProps = {
	switchPointDetail: SwitchPointDto;
};

export const LampBranchRouter = ({ switchPointDetail }: LampBranchRouterProps) => {

	const { vetevId, zapinaciBodId } = useUrlParams();

	const { fetchApiState: dispatchLampBranchDetail, apiState: detailApiState } = useApiState<LampBranchDto>();

	useEffect(() => {
		if (!isApiStateDispatched(detailApiState))
			dispatchLampBranchDetail(() => getLampBranchDetail(vetevId, zapinaciBodId));
	}, [detailApiState]);

	return (
		<SkeletonLoader
			data={[detailApiState]}
			render={([lampBranchDetail]) => (
				<div>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("vetev", { vetevId, zapinaciBodId })}
						label={`Větev ${lampBranchDetail.oznaceni}`}
					/>
					<Routes>
						<Route path={"*"} element={
							<LampBranchScreen lampBranchDetail={lampBranchDetail}
											  switchPointDetail={switchPointDetail} />
						} />
						<Route path={"lampa/:lampaId/*"} element={
							<LampRouter />
						} />
					</Routes>
				</div>
			)}
		/>

	);
};