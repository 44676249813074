import { LIGHT_SOURCE_TYPES, LightDto } from "../../interfaces/LightDto";
import { createForm, FormMode } from "@pasport/react-common";
import { DetailCard, DetailColumn, DetailRow } from "@pasport/react-ui";


const Form = createForm<LightDto>();

interface LampBranchCardsProps {
	mode: FormMode,
	fullScreen?: boolean
}

export const LightCards = ({ mode, fullScreen }: LampBranchCardsProps) => {
	mode = FormMode.EDIT;
	return (
		<DetailRow>
			<DetailColumn fullSize={fullScreen}>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"typ"} label={"Typ svítidla"} mode={mode} required />
						<Form.EnumItem name={"svetelnyZdroj"} label={"Světelný zdroj"} mode={mode}
									   options={LIGHT_SOURCE_TYPES} />

						<Form.NumberItem name={"spotreba"} label={"Spotřeba"} mode={mode} unit={"w"} required />
						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
			</DetailColumn>
			<DetailColumn fullSize={fullScreen}>
			</DetailColumn>
		</DetailRow>
	);
};
