import { Route, Routes } from "react-router-dom";
import { LampScreen } from "../screens/LampScreen";
import { LampDiaryScreen } from "../screens/LampDiaryScreen";
import React, { useEffect } from "react";
import { LampDto } from "../interfaces";
import { BreadcrumbSetter, isApiStateDispatched, SkeletonLoader, useApiState } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { getLampDetail } from "../apis/lamp";

export const LampRouter = () => {

	const { lampaId, vetevId, zapinaciBodId } = useUrlParams();
	const {
		fetchApiState,
		apiState: lampDetailApiState,
		invalidateApiState: invalidateDetail,
	} = useApiState<LampDto>();

	useEffect(() => {
		if (!isApiStateDispatched(lampDetailApiState) || (lampDetailApiState.success && lampDetailApiState?.data?.id !== lampaId))
			fetchApiState(() => getLampDetail(lampaId, vetevId, zapinaciBodId));
	}, [lampDetailApiState]);

	return (
		<SkeletonLoader
			data={[lampDetailApiState]}
			render={([lampDetail]) => (
				<>
					<BreadcrumbSetter
						to={getAbsoluteRoutePath("lampa", { zapinaciBodId, vetevId, lampaId })}
						label={`Lampa ${lampDetail.oznaceni}`}
					/>
					<Routes>
						<Route path={"*"} element={
							<LampScreen lampDetail={lampDetail} invalidateDetail={invalidateDetail} />
						} />
						<Route path={"hlaseni/:lampaHlaseniId/*"}
							   element={<LampDiaryScreen lampDetail={lampDetail} />} />
					</Routes>
				</>
			)}
		/>
	);
};