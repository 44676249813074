import { SearchInput, Select, SelectOption } from "@pasport/react-ui";
import { SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS, useWindowSize } from "@pasport/react-common";

export enum SWITCH_POINT_ORDER {
	OZNACENI = "OZNACENI",
	CAST_OBCE = "CAST_OBCE",
	DATUM_POSL_ZAVADY = "DATUM_POSL_ZAVADY",
	DATUM_POSL_UDRZBY = "DATUM_POSL_UDRZBY",
	DATUM_PLANOVANE_REVIZE = "DATUM_PLANOVANE_REVIZE",
}

const SORT_OPTIONS: SelectOption[] = [
	{ value: SWITCH_POINT_ORDER.OZNACENI, label: "Označení" },
	{ value: SWITCH_POINT_ORDER.CAST_OBCE, label: "Část obce" },
	{ value: SWITCH_POINT_ORDER.DATUM_POSL_ZAVADY, label: "Datum poslední hlášené závady" },
	{ value: SWITCH_POINT_ORDER.DATUM_POSL_UDRZBY, label: "Datum poslední údržby" },
	{ value: SWITCH_POINT_ORDER.DATUM_PLANOVANE_REVIZE, label: "Datum plánované revize" },
];


interface SwitchPointListHeaderProps {
	onTextChange: (searchQuery: string) => void,
	setSorting: (sortMethod: SWITCH_POINT_ORDER) => void,
	setSortDirection: (sortMethod: SORT_DIRECTION) => void,
}


export const SwitchPointListHeader = ({ onTextChange, setSorting, setSortDirection }: SwitchPointListHeaderProps) => {
	const { isMobile } = useWindowSize();
	const handleChange = (value: string) => {
		setSorting(value as SWITCH_POINT_ORDER);
	};

	const handleDirection = (value: string) => {
		setSortDirection(value as SORT_DIRECTION);
	};

	return (
		<div className="d-flex align-items-end justify-content-between mb-2 flex-wrap gap-2">
			<div className="d-flex align-items-center gap-3 flex-grow-1">
				<div style={{ width: "100%", maxWidth: isMobile ? "100%" : 300 }}>
					<SearchInput placeholder={"Hledat dle označení"} onTextChange={onTextChange} />
				</div>
			</div>
			<div className="d-flex gap-2 align-items-center  ms-auto">
				<span className="text-nowrap">Seřadit podle:</span>
				<div style={{ width: "100%", maxWidth: 300 }}>
					<Select
						onChange={handleChange}
						options={SORT_OPTIONS}
					/>
				</div>
				<div style={{ width: "100%", maxWidth: 130 }}>
					<Select
						onChange={handleDirection}
						options={SORT_DIRECTION_SELECT_OPTIONS}
					/>
				</div>
			</div>
		</div>
	);
};