import { SwitchPointDto } from "../interfaces";
import { SwitchPointList } from "../components/SwitchPoint";
import { LampSearchModal } from "../components/Lamp";
import { Page, PageHeader, SkeletonLoader, TabButton, useApiState, useWindowSize } from "@pasport/react-common";
import { useEffect, useState } from "react";
import { AddIcon, DiaryIcon, MapIcon, SearchIcon } from "@pasport/react-ui";
import { getAbsoluteRoutePath } from "../core/routing";
import { getSwitchPointList } from "../apis/switchPoint";


export const HomeScreen = () => {
	const { isMobile } = useWindowSize();
	const { fetchApiState, apiState } = useApiState<SwitchPointDto[]>();
	const [visibleSearchLampModal, setVisibleSearchLampModal] = useState(false);

	useEffect(() => {
		fetchApiState(() => getSwitchPointList());
	}, []);

	return (
		<Page>
			<SkeletonLoader
				data={[apiState]}
				render={([switchPointList]) => (
					<>
						<div className="container-xl pt-5 pb-5 px-0">
							<div>
								{switchPointList && (
									<div className="">
										{isMobile ? (
											<div
												className="d-flex justify-content-between align-items-center flex-column">
												<div className="">
													<TabButton label="Přidat zapínací bod" to="/pridat-zapinaci-bod"
															   icon={<AddIcon />} />
												</div>
												<PageHeader title={"Vyberte zapínací bod"} />
											</div>
										) : (
											<div className="d-flex justify-content-between align-items-start">
												<PageHeader title={"Vyberte zapínací bod"} />
												<div className="">
													{/*<TabButton label="Zobrazit mapu obce" to="https://test-map.smsdata.cz/map/?pas=vo&obj=obec" external target={"_blank"} icon={<MapIcon/>}/>*/}
													<TabButton label="Vyhledat lampu"
															   onPress={() => setVisibleSearchLampModal(true)}
															   icon={<SearchIcon />} />
													<TabButton label="Zobrazit mapu obce" to="/mapa" target={"_blank"}
															   icon={<MapIcon />} />
													<TabButton label="Poslední hlášení"
															   to={getAbsoluteRoutePath("vsechnaHlaseni", {})}
															   icon={<DiaryIcon />} />
													<TabButton label="Přidat zapínací bod" to="/pridat-zapinaci-bod"
															   icon={<AddIcon />} />
												</div>
											</div>
										)}

										<SwitchPointList items={switchPointList} />
									</div>
								)}
							</div>
							<LampSearchModal visible={visibleSearchLampModal}
											 onClose={() => setVisibleSearchLampModal(false)} />

						</div>
					</>
				)}
			/>
		</Page>

	);
};
