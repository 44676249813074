import { SwitchPointDto } from "../../interfaces";
import {
	apiToDate,
	apiToNumber,
	apiToPosition,
	apiToString,
	convertInnerLocationToGPS,
	dateToApi,
	numberToApi,
	stringToApi,
} from "@pasport/react-common";
import { MapZbData, MapZbDto, SwitchPointApiDto } from "./SwitchPointApi.dto";

export const mapApiToSwitchPoint = (switchPointApi: SwitchPointApiDto): SwitchPointDto => {
	return {
		id: apiToNumber(switchPointApi.zb_id),
		castObce: apiToString(switchPointApi.nazev_casti_obce),
		datumPosledniZavady: apiToDate(switchPointApi.datum_posl_zavady),
		datumPlanovaneRevize: apiToDate(switchPointApi.datum_plan_revize),
		oznaceni: apiToString(switchPointApi.oznaceni),
		uzemiId: apiToNumber(switchPointApi.uzemi_id),
		umisteni: apiToString(switchPointApi.umistneni),
		datumPosledniUdrzby: apiToDate(switchPointApi.datum_posl_servisu),
		poznamka: apiToString(switchPointApi.poznamka),
		pozice: apiToPosition(switchPointApi.st_x, switchPointApi.st_y),
		hash: apiToString(switchPointApi.substring),
	};
};

export const mapSwitchPointToApi = (switchPoint: SwitchPointDto) => {
	return {
		uzemi_id: numberToApi(switchPoint.uzemiId),
		oznaceni: stringToApi(switchPoint.oznaceni),
		umistneni: stringToApi(switchPoint.umisteni),
		DXdatum_plan_revize: dateToApi(switchPoint.datumPlanovaneRevize), //TODO
		poznamka: stringToApi(switchPoint.poznamka),
	};
};

export const mapApiToZbData = (zb: MapZbDto): MapZbData => {
	const convert = convertInnerLocationToGPS;
	const x = parseFloat(zb.st_x || "0");
	const y = parseFloat(zb.st_y || "0");

	return {
		id: zb.zb_id,
		oznaceni: zb.oznaceni,
		location: convert([x, y]),
	};
};
