import React from "react";
import { MarkerPopup } from "@pasport/react-common";
import { Marker } from "maps-api";
import { getAbsoluteRoutePath } from "../../../core/routing";
import { MapLampData } from "../../../apis/lamp/LampApi.dto";

export default function LampPopup({
									  lamp,
									  opened,
									  marker,
								  }: {
	lamp: MapLampData;
	opened: boolean;
	marker: Marker;
}) {
	const url = getAbsoluteRoutePath("lampa", {
		lampaId: lamp.id,
		zapinaciBodId: lamp.zbId,
		vetevId: lamp.vetevId,
	});
	return !opened ? (
		<></>
	) : (
		<MarkerPopup
			title="Lampa"
			marker={marker}
			detailUrl={url}
			items={[
				{ label: "Označení", value: lamp.oznaceni },
				{ label: "Větev", value: lamp.oznaceniVetve },
			]}
		/>
	);
}
