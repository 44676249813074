import React, { useEffect } from "react";
import LampPopup from "./LampPopup";
import { getBranchColor } from "../../../hooks/map/useBranchColor";
import { CLOSE_ALL_POPUPS_EVENT, MapObject, Marker } from "@pasport/react-common";
import { useShowMapLabels } from "../../../hooks/map/useShowMapLabels";
import { MapLampData } from "../../../apis/lamp/LampApi.dto";

export default function Lamp({
	lamp,
	map,
	marker,
}: {
	lamp: MapLampData;
	map: MapObject;
	marker: Marker;
}) {
	const iconSize = 30;
	const circleSize = 24;

	const color = getBranchColor(lamp.vetevId);
	const { isOn: showAsNumber } = useShowMapLabels();

	const [popupOpened, setPopupOpened] = React.useState(false);

	const onClick = () => {
		const opened = !popupOpened;
		window.dispatchEvent(new Event(CLOSE_ALL_POPUPS_EVENT));
		setPopupOpened(opened);
	};

	useEffect(() => {
		const close = () => {
			setPopupOpened(false);
		};

		window.addEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		return () => {
			window.removeEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		};
	}, []);

	return (
		<div
			style={{
				// padding: 5,
				// borderRadius: 10,
				transform: "translate(-50%, -50%)",
			}}
		>
			{!showAsNumber ? (
				<>
					<div onClick={onClick}>
						<div
							style={{
								backgroundColor: color,
								width: circleSize,
								height: circleSize,
								borderRadius: "50%",
								position: "absolute",
								left: 0,
								top: -2,
								transform: "translate(-50%, -50%)",
							}}
						/>

						<img
							src={"icons/lamp.png"}
							width={iconSize}
							height={iconSize}
							style={{
								position: "absolute",
								// left: "50%",
								// top: "50%",
								transform: "translate(-50%, -50%)",
							}}
						/>
					</div>
					<LampPopup lamp={lamp} opened={popupOpened} marker={marker} />
				</>
			) : (
				<div
					style={{
						backgroundColor: "white",
						width: 24,
						height: 24,
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<strong>{lamp.oznaceni}</strong>
				</div>
			)}
		</div>
	);
}
