import { RoutesParamName, RoutesParents, RoutesPaths } from "@pasport/react-common";

type SCREEN_PROPS_ZAPINACI_BOD = {
	zapinaciBodId: number;
};

type SCREEN_PROPS_PRIDAT_ZB = {};

type SCREEN_PROPS_VSECHNA_HLASENI = {};

type SCREEN_PROPS_ZB_HLASENI = SCREEN_PROPS_ZAPINACI_BOD & {
	zbHlaseniId: number;
};

type SCREEN_PROPS_VETEV = SCREEN_PROPS_ZAPINACI_BOD & {
	vetevId: number;
};

type SCREEN_PROPS_LAMPA = SCREEN_PROPS_VETEV & {
	lampaId: number;
};

type SCREEN_PROPS_SVITIDLO = SCREEN_PROPS_LAMPA & {
	svitidloId: number;
};
type SCREEN_PROPS_LAMPA_HLASENI = SCREEN_PROPS_LAMPA & {
	lampaHlaseniId: number;
};

type SCREEN_PROPS_PRIDAT_ZB_HLASENI = {
	zapinaciBodId: number;
	lau: number;
	hash: string;
};
type SCREEN_PROPS_PRIDAT_LAMPA_HLASENI = {
	zapinaciBodId: number;
	vetevId: number;
	lampaId: number;
	lau: number;
	hash: string;
};

export type RouterProps = {
	home: undefined;
	zapinaciBod: SCREEN_PROPS_ZAPINACI_BOD;
	pridatZapinaciBod: SCREEN_PROPS_PRIDAT_ZB;
	zapinaciBodHlaseni: SCREEN_PROPS_ZB_HLASENI;
	vetev: SCREEN_PROPS_VETEV;
	lampa: SCREEN_PROPS_LAMPA;
	lampaHlaseni: SCREEN_PROPS_LAMPA_HLASENI;
	svitidlo: SCREEN_PROPS_SVITIDLO;
	pridatZapinaciBodHlaseni: SCREEN_PROPS_PRIDAT_ZB_HLASENI;
	pridatLampaHlaseni: SCREEN_PROPS_PRIDAT_LAMPA_HLASENI;
	vsechnaHlaseni: SCREEN_PROPS_VSECHNA_HLASENI;
	spravovatUdaje: {};
	mapa: undefined;
};

export type RouterParamName = RoutesParamName<RouterProps>;
export const RouterPaths: RoutesPaths<RouterProps> = {
	home: "",
	zapinaciBod: "zapinaci-bod/:zapinaciBodId",
	pridatZapinaciBod: "pridat-zapinaci-bod",
	zapinaciBodHlaseni: "hlaseni/:zbHlaseniId",
	vetev: "vetev/:vetevId",
	lampa: "lampa/:lampaId",
	lampaHlaseni: "hlaseni/:lampaHlaseniId",
	svitidlo: "svitidlo/:svitidloId",

	pridatLampaHlaseni: "nahlaseni-vo/:lau/:zapinaciBodId/:vetevId/:lampaId/:hash?",
	pridatZapinaciBodHlaseni: "nahlaseni-zb/:lau/:zapinaciBodId/:hash?",
	vsechnaHlaseni: "vsechna-hlaseni",
	spravovatUdaje: "udaje",
	mapa: "mapa",
};

export const RouterParents: RoutesParents<RouterProps> = {
	home: null,
	zapinaciBod: null,
	pridatZapinaciBod: null,
	zapinaciBodHlaseni: "zapinaciBod",
	vetev: "zapinaciBod",
	lampa: "vetev",
	lampaHlaseni: "lampa",
	svitidlo: "lampa",

	pridatLampaHlaseni: null,
	pridatZapinaciBodHlaseni: null,
	vsechnaHlaseni: null,
	spravovatUdaje: null,
	mapa: null,
};
