import { LampBranchDto, SwitchPointDto } from "../../interfaces";
import {
	dateToString,
	downloadFile,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchEntityDetail,
	fetchEntityList,
	fetchUpdateEntity,
	normalizeText,
} from "@pasport/react-common";
import { mapApiToLampBranch, mapLampBranchToApi } from "./LampBranchApi.map";
import { mapApiToLamp } from "../lamp/LampApi.map";

//lamp branch object

export const createLampBranch = async (lampBranch: LampBranchDto, switchPointId: number) => {
	return fetchCreateEntity("insertVetev", {
		...mapLampBranchToApi(lampBranch),
		zb_id: switchPointId,
	});
};

export const getLampBranchDetail = async (lampBranchId: number, switchPointId: number) => {
	return (
		fetchEntityDetail(`vet/detail/${switchPointId}/${lampBranchId}`, mapApiToLampBranch)
	);

};

export const deleteLampBranch = async (lampBranchId: number, switchPointId: number) => {
	return fetchDeleteEntity("deleteVetev", {
		Vetev: lampBranchId,
		ZapBod: switchPointId,
	});
};


export const getLampListInLampBranch = async (lampBranchId: number, switchPointId: number) => {
	return (
		fetchEntityList(`lampa/seznam/${switchPointId}/${lampBranchId}`, mapApiToLamp)
	);
};


export const updateLampBranchDetail = async (lampBranch: LampBranchDto, switchPointId: number) => {
	return fetchUpdateEntity("updateVetev", {
		...mapLampBranchToApi(lampBranch),
		idVetev: lampBranch.id,
		idZapBod: switchPointId,
	});
};

export const downloadLampListTable = async (lampBranch: LampBranchDto, switchPoint: SwitchPointDto) => {
	const fileName = `${normalizeText(switchPoint.castObce)}_${normalizeText((switchPoint.oznaceni))}_${normalizeText(lampBranch.oznaceni)}_${dateToString(new Date(), "_")}.csv`;
	return downloadFile(`ExportLampaVO/${switchPoint.uzemiId}/${switchPoint.id}/${lampBranch.id}`, fileName, true);
};