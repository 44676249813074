import { CreateDiaryEntryParametricScreen } from "./CreateDiaryEntryParametricScreen";
import { useUrlParams } from "../../core/routing";
import {
	createDiaryEntryForSwitchPoint,
	getDiaryEntryListInSwitchPoint,
	updateIsSolvedDiaryEntryForSwitchPoint,
} from "../../apis/switchPoint";


export const CreateSwitchPointDiaryEntryScreen = () => {
	const { zapinaciBodId, lauId } = useUrlParams();

	return (
		<CreateDiaryEntryParametricScreen
			dispatchCreateDiaryEntry={(formValues) => createDiaryEntryForSwitchPoint(formValues, zapinaciBodId, lauId)}
			dispatchUpdateDiaryEntryIsSolved={(parentDiaryEntryId, isSolved) => updateIsSolvedDiaryEntryForSwitchPoint(parentDiaryEntryId, zapinaciBodId, isSolved)}
			dispatchDiaryEntries={() => getDiaryEntryListInSwitchPoint(zapinaciBodId)}
			variant={"switchPoint"}
		/>
	);
};