import { SwitchPointCreateForm } from "../components/SwitchPoint/SwitchPointCreateForm";

import { Page, PageHeader } from "@pasport/react-common";


export const SwitchPointAddScreen = () => {
	return (
		<Page>
			<PageHeader title={"Přidat zapínací bod"} />
			<SwitchPointCreateForm />

		</Page>
	);
};
