import { ContactDto } from "../../interfaces/ContactDto";
import { createForm, FormMode, FormVersion } from "@pasport/react-common";


const Form = createForm<ContactDto>();

interface ContactFormProps {
	mode: FormMode,
	version?: FormVersion,
}


export const ContactForm = ({ mode, version = FormVersion.UPDATE }: ContactFormProps) => {


	return (
		<div>
			<div className="">
				<Form.TextItem name={"email"} label={"Email"} mode={mode} required />
				{/*<Form.TextItem name={"telefon"} label={"Telefonní číslo"} mode={mode}/>*/}

				<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
			</div>


		</div>
	);
};
