import { ContactForm } from "./ContactForm";
import { ContactDto } from "../../interfaces/ContactDto";
import { FormMode, FormVersion, FormWithCreate, useAlert } from "@pasport/react-common";
import { createContact } from "../../apis/contact";


const defaultContact: ContactDto = {
	id: -1,
	poznamka: "",
	email: "",
	telefon: "",


};

interface ContactCreateFormProps {
	onCreate?: () => void,
}

export const ContactCreateForm = ({ onCreate }: ContactCreateFormProps) => {
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithCreate
			initValues={defaultContact}
			dispatchCreate={(value) => createContact(value)}
			onCreateSuccess={(lampId: number) => {
				addSuccessAlert({ message: "K obci byl přidán nový kontakt." });
				if (onCreate) onCreate();
			}}
			bottomButton
		>
			{() => <ContactForm mode={FormMode.EDIT} version={FormVersion.CREATE} />}
		</FormWithCreate>
	);

};

