import React from "react";
import { SwitchPointDto } from "../interfaces";
import { useNavigate } from "react-router-dom";
import { DiaryParametricScreen, useAlert, useBreadcrumbs } from "@pasport/react-common";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import {
	createDiaryEntryForSwitchPoint, deleteDiaryEntryForSwitchPoint, deleteSwitchPointDiaryEntryPhoto,
	getChildrenOfDiaryEntryListForSwitchPoint,
	getDiaryEntryDetailForSwitchPoint,
	getDiaryEntryPhotoListForSwitchPoint, updateDiaryEntryForSwitchPoint, uploadSwitchPointDiaryEntryPhoto,
} from "../apis/switchPoint";

interface SwitchPointDiaryScreenProps {
	switchPointDetail: SwitchPointDto;
}

export const SwitchPointDiaryScreen = ({ switchPointDetail }: SwitchPointDiaryScreenProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	const { zbHlaseniId, zapinaciBodId } = useUrlParams();

	useBreadcrumbs({
		to: getAbsoluteRoutePath("zapinaciBodHlaseni", { zbHlaseniId, zapinaciBodId }),
		label: "Deníkové hlášení",
	});


	return <DiaryParametricScreen
		identifier={zbHlaseniId}
		title={`Deníkové hlášení zapínacího bodu ${switchPointDetail.oznaceni}`}
		getLink={(diaryEntry) => getAbsoluteRoutePath("zapinaciBodHlaseni", {
			zapinaciBodId: zapinaciBodId,
			zbHlaseniId: diaryEntry.id,
		})}

		dispatchDetail={() => getDiaryEntryDetailForSwitchPoint(zbHlaseniId, zapinaciBodId)}
		dispatchChildren={() => getChildrenOfDiaryEntryListForSwitchPoint(
			zbHlaseniId, zapinaciBodId,
		)}

		dispatchUpdate={(value) => {
			return updateDiaryEntryForSwitchPoint(value, zapinaciBodId);
		}}
		onUpdateSuccess={() => {
			addSuccessAlert({ message: "Deníkový záznam byl upraven" });
		}}

		dispatchDelete={() => deleteDiaryEntryForSwitchPoint(zbHlaseniId, zapinaciBodId)}
		onDeleteSuccess={() => {
			addSuccessAlert({ message: "Deníkový záznam byl odstraněn" });
			navigate(
				getAbsoluteRoutePath("zapinaciBod", {
					zapinaciBodId,
				}),
			);
		}}

		dispatchCreate={(formValues) => createDiaryEntryForSwitchPoint(
			{ ...formValues, parentId: zbHlaseniId }, zapinaciBodId,
		)}
		onCreateSuccess={(diaryEntryId: number) => {
			addSuccessAlert({ message: "Bylo přidáno deníkové hlášení." });
			navigate(
				getAbsoluteRoutePath("zapinaciBodHlaseni", {
					zapinaciBodId,
					zbHlaseniId,
				}) + "/reakce",
			);
		}}

		dispatchPhotoList={() => getDiaryEntryPhotoListForSwitchPoint(zbHlaseniId, zapinaciBodId)}
		dispatchPhotoUpload={(upload) => uploadSwitchPointDiaryEntryPhoto(upload, zbHlaseniId)}
		dispatchPhotoDelete={(file) => deleteSwitchPointDiaryEntryPhoto(file, zbHlaseniId, zapinaciBodId)}
	/>;
};