import React, { useEffect } from "react";

import {
	Breadcrumbs,
	isApiStateDispatched,
	Page,
	PageHeader,
	SkeletonLoader,
	TabRoutes,
	useApiState,
} from "@pasport/react-common";

import { DiaryIcon } from "@pasport/react-ui";
import { DiaryListAdvanced } from "../common/diary/DiaryListAdvanced";
import { getAllDiaryEntriesForSwitchPoints } from "../apis/switchPoint";
import { DiaryEntryWithVoDescriptorDto } from "../overrides/diary";
import { getAllDiaryEntriesForLamps } from "../apis/lamp";


export const AllDiaryEntryListScreen = () => {


	const {
		fetchApiState: dispatchSwitchPointDiary,
		apiState: switchPointDiary,
		invalidateApiState: invalidateSwitchPointDiary,
	} = useApiState<DiaryEntryWithVoDescriptorDto[]>();
	const {
		fetchApiState: dispatchLampDiary,
		apiState: lampDiary,
		invalidateApiState: invalidateLampDiary,
	} = useApiState<DiaryEntryWithVoDescriptorDto[]>();


	useEffect(() => {
		if (!isApiStateDispatched(switchPointDiary))
			dispatchSwitchPointDiary(() => getAllDiaryEntriesForSwitchPoints());
	}, [switchPointDiary]);

	useEffect(() => {
		if (!isApiStateDispatched(lampDiary))
			dispatchLampDiary(() => getAllDiaryEntriesForLamps());
	}, [lampDiary]);

	return (
		<>

			<Breadcrumbs />

			<Page>
				<PageHeader title={"Poslední deníková hlášení v obci"} />
				<TabRoutes
					links={[
						{
							to: "lampy",
							label: "Hlášení k lampám",
							icon: <DiaryIcon />,
							content: (
								<SkeletonLoader
									data={[lampDiary]}
									render={([diaryEntryList]) => (
										<DiaryListAdvanced items={diaryEntryList} variant={"lamp"} />
									)}
								/>
							),
						}, {
							to: "zb",
							label: "Hlášení k zapínacím bodům",
							icon: <DiaryIcon />,
							content: (
								<SkeletonLoader
									data={[switchPointDiary]}
									render={([diaryEntryList]) => (
										<DiaryListAdvanced items={diaryEntryList} variant={"switchPoint"} />
									)}
								/>
							),
						},
					]}
				/>
			</Page>
		</>
	);
};