import { ContactDto } from "../../interfaces/ContactDto";
import { apiToNumber, apiToString, stringToApi } from "@pasport/react-common";
import { ContactApiDto } from "./ContactApi.dto";


export const mapApiToContact = (response: ContactApiDto): ContactDto => {
	return {
		id: apiToNumber(response.kontakt_id),
		email: apiToString(response.email),
		telefon: apiToString(response.tel_cislo),
		poznamka: apiToString(response.poznamka),
	};
};

export const mapContactToApi = (contact: ContactDto) => {
	return {
		email: stringToApi(contact.email),
		tel_cislo: stringToApi(contact.telefon),
		poznamka: stringToApi(contact.poznamka),
	};
};