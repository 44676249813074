export const LIGHT_SOURCE_TYPES = [
    "LED", "halogen", "zářivka", "sodík", "výbojka", "žárovka"
];

export type LightSourceType = typeof LIGHT_SOURCE_TYPES[number];



export interface LightDto {
    id: number;
    typ: string;
    svetelnyZdroj: LightSourceType;
    spotreba: number,
    poznamka?: string
}