import { LampBranchDto } from "../../interfaces";
import { LampBranchCards } from "./LampBranchCards";
import { createLampBranch } from "../../apis/lampBranch/LampBranchApis";
import { useNavigate } from "react-router-dom";
import { useAlert, FormWithCreate } from "@pasport/react-common";
import { FormMode } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

const defaultLampBranch: LampBranchDto = {
	id: -1,
	oznaceni: "",
	pocetLamp: 0,
	celkovaSpotreba: 0,
	typKabelu: "",
	umisteniKabelu: "vzdušný",
	poznamka: "",
	pocetSvitidel: null,
	oznaceniZB: null,
	castObce: null,
};


interface LampBranchCreateFormProps {
	switchPointId: number,
	onCreate: () => void
}

export const LampBranchCreateForm = ({ switchPointId, onCreate }: LampBranchCreateFormProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithCreate
			initValues={defaultLampBranch}
			dispatchCreate={(value) => {
				console.log("update for ", value);
				return createLampBranch(value, switchPointId);
			}}
			onCreateSuccess={(lampBranchId: number) => {
				addSuccessAlert({ message: "Byla vytvořena větev veřejného osvětlení." });
				navigate(
					getAbsoluteRoutePath("vetev", {
						zapinaciBodId: switchPointId,
						vetevId: lampBranchId,
					}),
				);
				onCreate();
			}}
		>
			{() => <LampBranchCards mode={FormMode.EDIT} />}
		</FormWithCreate>
	);

};

