import { SwitchPointCards } from "./SwitchPointCards";
import { SwitchPointDto } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { FormWithUpdate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";
import {
	deleteSwitchPoint,
	deleteSwitchPointPhoto,
	getSwitchPointPhotoList, updateSwitchPointDetail,
	uploadSwitchPointPhoto,
} from "../../apis/switchPoint";


interface SwitchPointCardsWrapperProps {
	switchPoint: SwitchPointDto,
	qrCodeUrl?: string,
	onUpdate?: () => void,
}

export const SwitchPointCardsWrapper = ({ switchPoint, qrCodeUrl, onUpdate }: SwitchPointCardsWrapperProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithUpdate
			initValues={switchPoint}
			dispatchUpdate={(value) => {
				return updateSwitchPointDetail(value);
			}}
			dispatchDelete={() => deleteSwitchPoint(switchPoint.id)}
			onUpdateSuccess={() => {
				addSuccessAlert({ message: "Zapínací bod byl upraven." });
				if (onUpdate) onUpdate();
			}}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Zapínací bod byl odstraněn." });
				navigate(
					getAbsoluteRoutePath("home", undefined),
				);
			}}
		>
			{({ mode }) => (
				<SwitchPointCards
					mode={mode}
					switchPointId={switchPoint.id}
					dispatchPhotoUpload={(props) => uploadSwitchPointPhoto(props, switchPoint.id)}
					dispatchPhotoDelete={(file) => deleteSwitchPointPhoto(file, switchPoint.id)}
					dispatchPhotoList={() => getSwitchPointPhotoList(switchPoint.id)}
					qrCode={qrCodeUrl ? { url: qrCodeUrl, label: switchPoint.oznaceni } : undefined}
				/>
			)}
		</FormWithUpdate>
	);
};

