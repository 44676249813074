import { getAbsoluteRoutePath } from "../core/routing";


export const getLampQrCodeUrl = (switchPointId: number, lampBranchId: number, lampId: number, lauId: number, hash: string) => {
	const absolute = getAbsoluteRoutePath("pridatLampaHlaseni", {
		zapinaciBodId: switchPointId,
		vetevId: lampBranchId,
		lampaId: lampId,
		lau: lauId,
		hash,
	});
	return window.location.origin + absolute;
};