import { ContactDto } from "../../interfaces/ContactDto";
import React, { useState } from "react";
import { useApiState, useApiStateSuccess } from "@pasport/react-common";
import { Row, RowItem, Card, CardBody, DeleteModal } from "@pasport/react-ui";
import { deleteContact } from "../../apis/contact";


interface ContactItemProps {
	contact: ContactDto,
	onDeleteSuccess: () => void
}

export const ContactItem = ({ contact, onDeleteSuccess }: ContactItemProps) => {
	const { fetchApiState: deleteFetch, apiState: deleteApiState } = useApiState();
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

	const handleDeleteRequest = () => {
		deleteFetch(() => deleteContact(contact.id));
	};


	useApiStateSuccess(() => {
		setIsDeleteModalVisible(false);
		if (onDeleteSuccess) onDeleteSuccess();
	}, [deleteApiState]);

	const triggerDelete = () => {
		setIsDeleteModalVisible(true);
	};


	return (
		<>
			<Card flat>
				<CardBody>
					{triggerDelete && (
						<a onClick={(e) => {
							e.preventDefault();
							triggerDelete();
						}} href={"/"} className="p-2 link-danger fs-3 text-decoration-none lh-1"
						   style={{ position: "absolute", right: 0, top: 0 }}>
							<span className="px-1">×</span>
						</a>
					)}
					<Row simple>
						<RowItem label={"Email"} size={6}>{contact.email}</RowItem>
					</Row>
					<Row simple>
						<RowItem label={"Poznámka"} size={6}>{contact.poznamka}</RowItem>
					</Row>
				</CardBody>
			</Card>

			<DeleteModal
				show={isDeleteModalVisible}
				onClose={() => setIsDeleteModalVisible(false)}
				isDeleting={deleteApiState.loading}
				onConfirmPress={handleDeleteRequest}
			/>
		</>
	);
};