import { SwitchPointDto } from "../../interfaces";
import { Link } from "react-router-dom";
import { Card, CardDivider, CardSection } from "@pasport/react-ui";
import { formatDate } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";

interface SwitchPointItemProps {
	value: SwitchPointDto;
}

interface DataItemProps {
	label: string,
	value: string
}

const formatDateNullable = (value: Date | null) => {
	return value ? formatDate(value) : "—";
};

const DataItem = ({ label, value }: DataItemProps) => {
	const isViewMode = true;
	const key = "sadf";
	return <div className="d-flex flex-column">
		{isViewMode && <small className="lh-sm">{label}</small>}
		{!isViewMode && (
			<div className="d-flex justify-content-between">
				<label htmlFor={key}
					   className="form-label mb-1">
					<small>{label}</small>
				</label>
			</div>
		)}
		<div className="fw-bold">{value ? value : "—"}</div>
	</div>;
};

export const SwitchPointItem = ({ value }: SwitchPointItemProps) => {
	const link = getAbsoluteRoutePath("zapinaciBod", {
		zapinaciBodId: value.id,
	});
	const description = [value.castObce, value.umisteni].filter(value => value && value !== "").join(", ");
	return (
		<Card>
			<div className="d-flex flex-row align-items-center">
				<div className="flex-grow-1 p-0 g-0 align-items-center my-auto">
					<CardSection>
						<div className="fs-4 fw-bold m-0 g-0">{value.oznaceni}</div>
						{description && (
							<div className="d-flex gap-2 align-items-center flex-wrap">
								<span className="text-muted lh-1">{description}</span>
							</div>
						)}
					</CardSection>
				</div>
				{link !== undefined && (
					<div className="d-flex align-items-center justify-content-center p-3 bg">
						<Link to={link} className="stretched-link" />
						<i className="bi bi-chevron-right fs-4"></i>
					</div>
				)}
			</div>
			<CardDivider />
			<CardSection>
				<div className="d-flex flex-column gap-2">
					<DataItem label="Poslední závada" value={formatDateNullable(value.datumPosledniZavady)} />
					<DataItem label="Plánovaná revize" value={formatDateNullable(value.datumPlanovaneRevize)} />
					<DataItem label="Poslední údržba" value={formatDateNullable(value.datumPosledniUdrzby)} />
				</div>
			</CardSection>
			{value.poznamka && (
				<>
					<CardDivider />
					<CardSection version="comment">
						<div className="small text-muted pe-2">Poznámka</div>
						<div className="">{value.poznamka}</div>
					</CardSection>
				</>
			)}
		</Card>
	);

};