export const getBranchColor = (branchId: number) => {
	const MAIN_CIRCLE_PARTS_COUNT = 10; //musi byt sude
	const MAIN_CIRCLE_START_OFFSET = 3;
	const MAIN_CIRCLE_STEPS = 7;

	const hueIndex = branchId * MAIN_CIRCLE_STEPS;
	const hue = (hueIndex + MAIN_CIRCLE_START_OFFSET) * (360 / MAIN_CIRCLE_PARTS_COUNT);

	const LIGHTNESS_STEPS = 20;
	const MAX_LIGHTNESS_STEPS = 2;

	let lineIndex = Math.floor(branchId / MAIN_CIRCLE_PARTS_COUNT);
	lineIndex %= MAX_LIGHTNESS_STEPS + 1;

	const opposite = branchId % 2 != 1 ? 1 : -1;
	const isDarker = (lineIndex % 2 != 1 ? 1 : -1) * opposite;

	let lightness = 50;
	lightness += Math.ceil(lineIndex / 2) * LIGHTNESS_STEPS * isDarker;

	const hslString = `hsl(${hue}, 100%, ${lightness}%)`;
	return hslString;
};
