import { LightDto } from "../../interfaces/LightDto";
import { fetchCreateEntity, fetchDeleteEntity, fetchUpdateEntity } from "@pasport/react-common";
import { mapLightToApi } from "./LightApi.map";

export const updateLightDetail = async (light: LightDto, lampId: number, lampBranchId: number, switchPointId: number) => {
	return fetchUpdateEntity("updateSvitidlo", {
		...mapLightToApi(light),
		svitidlo: light.id,
		lampa: lampId,
		vetev: lampBranchId,
		zapBod: switchPointId,
	});
};

export const deleteLight = async (lightId: number, lampId: number, lampBranchId: number, switchPointId: number) => {
	return fetchDeleteEntity("deleteSvitidlo", {
		Svitidlo: lightId,
		Lampa: lampId,
		Vetev: lampBranchId,
		ZapBod: switchPointId,
	});
};


export const createLight = async (
	light: LightDto, lampId: number, lampBranchId: number, switchPointId: number,
): Promise<number> => {
	return fetchCreateEntity("insertSvitidlo", {
		...mapLightToApi(light),
		lampa_id: lampId,
	});
};