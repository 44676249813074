import {useQrCodeImage} from "./useQrCodeImage";
import {Document, Image, Page, View, } from "@react-pdf/renderer";
import {QrCodeItem} from "./QrCodeItem";
import {getQrCodeImage, GetQrCodeImageReturn} from "./qrCodeHelpers";
import {useEffect, useState} from "react";

interface QrCodeDocumentProps {
    qrCodes: QrCodeItem[],
    onLoadChange: (isLoaded: boolean) => void
}
const ROW_COUNT = 4;
const PAGE_COUNT = ROW_COUNT * 5;

const logTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()},${date.getMilliseconds()}`
}

export const QrCodeDocument = ({qrCodes, onLoadChange} : QrCodeDocumentProps) => {
    const [qrImageList, setGrImageList] = useState<GetQrCodeImageReturn[]>([]);

    const loadQrCodeImages = async () => {
        onLoadChange(false)
        const qrDataList = await Promise.all(qrCodes.map(code => getQrCodeImage(code)));
        setGrImageList(qrDataList);
        onLoadChange(true);
    }

    useEffect(() => {
        (() => loadQrCodeImages())();
    },[])

    const pages = qrImageList.reduce((obj, image, currentIndex) => {
        const iPage = Math.floor(currentIndex/PAGE_COUNT);
        if(iPage >= obj.length){
            obj.push([]);
        }
        obj[iPage].push(image)
        return obj;
    }, [] as GetQrCodeImageReturn[][]);



    return (
        <Document>
            {pages.map((images,index) => (
                <Page size="A4" style={{padding:30}} key={index}>
                    <View style={{flex:1, gap:0, flexDirection:'row', flexWrap:'wrap',}} wrap>
                        {images.map((qrImage, index) => (
                            <View key={index} style={{alignItems:'center', width:'25%', border:1, borderStyle:'dotted',borderColor:'#cccccc', padding:2}} >
                                {/*<QrCodeDocImage {...qrCode}/>*/}
                                <View>
                                    <DocQrImage {...qrImage}/>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            ) )}

        </Document>
    );
}

const BASE_QR_CODE_WIDTH = 115;

const DocQrImage = ({imageUrl, width, height} : GetQrCodeImageReturn) => {
    return <Image src={imageUrl} style={{height:BASE_QR_CODE_WIDTH/width*height, width:BASE_QR_CODE_WIDTH}} />
}

const QrCodeDocImage = ({value, label} : QrCodeItem) => {
    const {imageUrl, width, height} = useQrCodeImage({value, label:label});
    console.log("value, label", value, label, imageUrl)
    if(imageUrl && imageUrl !== ""){
        return <Image src={imageUrl} style={{height:100/width*height, width:100}} />
    }
    return null;
    //return <Image src={"https://react-pdf.org/images/logo.png"}/>;
}