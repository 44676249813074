import { LampDto } from "../../interfaces";
import { LampCards } from "./LampCards";
import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { FormWithUpdate, QrCodeItem, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";
import { deleteLamp, deleteLampPhoto, getLampPhotoList, updateLampDetail, uploadLampPhoto } from "../../apis/lamp";


interface LampCardsUpdateProps {
	lamp: LampDto,
	lampBranchId: number,
	switchPointId: number,
	qrCodeItem?: QrCodeItem,
	lightCardRender?: ReactNode,
	onUpdate?: () => void
}

export const LampCardsUpdate = ({
									lamp,
									lampBranchId,
									switchPointId,
									qrCodeItem,
									lightCardRender,
									onUpdate,
								}: LampCardsUpdateProps) => {
	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	;

	return (
		<FormWithUpdate
			initValues={lamp}
			dispatchUpdate={(value) => {
				return updateLampDetail(value, lampBranchId, switchPointId);
			}}
			dispatchDelete={() => deleteLamp(lamp.id, lampBranchId, switchPointId)}
			onUpdateSuccess={() => {
				addSuccessAlert({ message: "Lampa byla upravena." });
				if (onUpdate) onUpdate();
			}}
			onDeleteSuccess={() => {
				addSuccessAlert({ message: "Lampa byla odstraněna." });
				navigate(
					getAbsoluteRoutePath("vetev", {
						vetevId: lampBranchId, zapinaciBodId: switchPointId,
					}),
				);
			}}
		>
			{({ mode }) => (
				<>
					<LampCards
						mode={mode}
						dispatchPhotoList={() => getLampPhotoList(lamp.id, lampBranchId, switchPointId)}
						dispatchPhotoUpload={(upload) => uploadLampPhoto(upload, lamp.id)}
						dispatchPhotoDelete={(file) => deleteLampPhoto(file, lamp.id, lampBranchId, switchPointId)}
						qrCode={qrCodeItem ?? undefined}
						lightCardRender={lightCardRender}
					/>
				</>
			)}
		</FormWithUpdate>
	);

};

