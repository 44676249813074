import { LampDto } from "../../interfaces";

import { ReactNode } from "react";
import { useField } from "formik";
import {
	createForm,
	FileDto,
	FileUploadDto,
	FormVersion,
	MapObject,
	PhotoListCard,
	PositionCard,
	PositionMapObject,
	QrCodeCard,
	QrCodeItem,
} from "@pasport/react-common";

import { DetailCard, DetailColumn, DetailItemRow, DetailRow, FormMode } from "@pasport/react-ui";

const Form = createForm<LampDto>();

interface LampCardsProps {
	mode: FormMode;
	version?: FormVersion;
	dispatchPhotoUpload?: (upload: FileUploadDto) => Promise<any>;
	dispatchPhotoList?: () => Promise<any>;
	dispatchPhotoDelete?: (upload: FileDto) => Promise<any>;
	qrCode?: QrCodeItem;
	lightCardRender?: ReactNode;
}

export const LampCards = ({
	mode,
	version = FormVersion.UPDATE,
	dispatchPhotoUpload,
	dispatchPhotoList,
	qrCode,
	lightCardRender,
	dispatchPhotoDelete,
}: LampCardsProps) => {
	const [positionField] = useField("pozice");

	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<div className="d-flex gap-4">
							<Form.TextItem
								name={"oznaceni"}
								label={"Označení lampy"}
								mode={mode}
								required
							/>
						</div>
						<DetailItemRow>
							<Form.TextItem
								name={"castObce"}
								label={"Část obce"}
								mode={mode}
								readonly
							/>
							<Form.TextItem
								name={"oznaceniZB"}
								label={"Označení zapínacího bodu"}
								mode={mode}
								readonly
							/>
						</DetailItemRow>
						<DetailItemRow>
							<Form.TextItem
								name={"oznaceniVetve"}
								label={"Označení větve"}
								mode={mode}
								readonly
							/>
							<span className="w-100"></span>
						</DetailItemRow>

						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>

				<PhotoListCard
					hide={version === FormVersion.CREATE}
					isEditMode={mode === FormMode.EDIT}
					dispatchPhotoUpload={dispatchPhotoUpload}
					loadImages={dispatchPhotoList}
					onPhotoDelete={dispatchPhotoDelete}
				/>
				{qrCode && <QrCodeCard title="QR kód lampy" {...qrCode} />}
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Vlastnosti">
					<div>
						<DetailItemRow>
							<Form.EnumItem
								name={"umisteni"}
								label={"Umístění"}
								mode={mode}
								options={["vlastní konstrukce", "sloup ČEZ", "na objektu"]}
							/>
							<Form.EnumItem
								name={"typSloupu"}
								label={"Typ sloupu"}
								mode={mode}
								options={["betonový", "kovový", "dřevěný", "příhradový", "žádný"]}
							/>
						</DetailItemRow>

						<DetailItemRow>
							<Form.NumberItem
								name={"vyskaSloupu"}
								label={"Výška sloupu"}
								mode={mode}
								unit={"m"}
								floatNumber
							/>
							<Form.NumberItem
								name={"pocetVylozniku"}
								label={"Počet výložníků"}
								mode={mode}
							/>
						</DetailItemRow>

						<DetailItemRow>
							<Form.NumberItem
								name={"pocetSvitidel"}
								label={"Počet svítidel"}
								mode={mode}
								readonly
							/>
							<Form.NumberItem
								name={"spotreba"}
								label={"Spotřeba"}
								mode={mode}
								unit={"w"}
								readonly
							/>
						</DetailItemRow>
					</div>
				</DetailCard>

				<DetailCard title="Deníkové údaje">
					<DetailItemRow>
						<Form.DateItem
							name={"datumPosledniUdrzby"}
							label={"Datum poslední údžby"}
							mode={mode}
							readonly
						/>
						<Form.DateItem
							name={"datumPosledniZavady"}
							label={"Datum poslední závady"}
							mode={mode}
							readonly
						/>
						<Form.DateItem
							name={"datumPlanovaneRevize"}
							label={"Datum plánované revize"}
							mode={mode}
						/>
					</DetailItemRow>
				</DetailCard>
				{lightCardRender}
				<PositionCard
					position={positionField.value}
					object={PositionMapObject.LAMP}
					mode={mode}
					version={version}
				/>
			</DetailColumn>
		</DetailRow>
	);
};
