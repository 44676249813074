import { LampBranchDto } from "../../interfaces";
import { apiToNumber, apiToString, apiToStringEnum, stringToApi } from "@pasport/react-common";
import { LampBranchApiDto } from "./LampBranchApi.dto";

export const mapApiToLampBranch = (response: LampBranchApiDto): LampBranchDto => {
	console.log("apiToLampBranch", response);
	return {
		id: apiToNumber(response.vetev_id),
		oznaceni: response.oznaceni ? apiToString(response.oznaceni) : apiToString(response.oznaceni_vetev),
		typKabelu: apiToString(response.typ_kabelu),
		umisteniKabelu: apiToStringEnum(response.umistneni_kabelu, ["vzdušný", "zemní"]) as "vzdušný" | "zemní" | null,
		pocetLamp: apiToNumber(response.pocet_lamp),
		pocetSvitidel: apiToNumber(response.pocet_svitidel),
		celkovaSpotreba: apiToNumber(response.celkova_spotreba),
		poznamka: apiToString(response.poznamka),

		castObce: apiToString(response.cast_obce),
		oznaceniZB: apiToString((response.oznaceni_zb)),

	};
};

export const mapLampBranchToApi = (lampBranch: LampBranchDto) => {
	return {
		oznaceni: stringToApi(lampBranch.oznaceni),
		typ_kabelu: stringToApi(lampBranch.typKabelu),
		umistneni_kabelu: stringToApi(lampBranch.umisteniKabelu),
		poznamka: stringToApi(lampBranch.poznamka),
	};
};