import { CreateDiaryEntryParametricScreen } from "./CreateDiaryEntryParametricScreen";
import { useUrlParams } from "../../core/routing";
import { createDiaryEntryForLamp, getDiaryEntriesInLamp, updateIsSolvedDiaryEntryForLamp } from "../../apis/lamp";

export const CreateLampDiaryEntryScreen = () => {
	const { lampaId, vetevId, zapinaciBodId, lauId } = useUrlParams();

	return (
		<CreateDiaryEntryParametricScreen
			dispatchCreateDiaryEntry={(formValues) => createDiaryEntryForLamp(formValues, lampaId, vetevId, zapinaciBodId, lauId)}
			dispatchUpdateDiaryEntryIsSolved={(parentDiaryEntryId, isSolved) => updateIsSolvedDiaryEntryForLamp(parentDiaryEntryId, lampaId, vetevId, zapinaciBodId, isSolved)}
			dispatchDiaryEntries={() => getDiaryEntriesInLamp(lampaId, vetevId, zapinaciBodId)}
			variant="lamp"
		/>
	);
};