import { LampBranchDto } from "../../interfaces";
import { createForm, QrCodeItem } from "@pasport/react-common";
import { DetailCard, DetailColumn, DetailItemRow, DetailRow, FormMode } from "@pasport/react-ui";
import { QrCodePdfButton } from "../../common/components/QrCodeCard";


const Form = createForm<LampBranchDto>();

interface LampBranchCardsProps {
	mode: FormMode,
	qrCodes?: QrCodeItem[],
	qrSetFileName?: string
}

export const LampBranchCards = ({ mode, qrCodes, qrSetFileName }: LampBranchCardsProps) => {
	return (
		<DetailRow>
			<DetailColumn>
				<DetailCard title="Základní informace">
					<div>
						<Form.TextItem name={"oznaceni"} label={"Označení větve"} mode={mode} required />

						<DetailItemRow>
							<Form.TextItem name={"castObce"} label={"Část obce"} mode={mode} readonly />
							<Form.TextItem name={"oznaceniZB"} label={"Označení zapínacího bodu"} mode={mode}
										   readonly />
						</DetailItemRow>

						<DetailItemRow>
							<Form.TextItem name={"typKabelu"} label={"Typ kabelů"} mode={mode} />
							<Form.EnumItem name={"umisteniKabelu"} label={"Umístění kabelů"} mode={mode}
										   options={["vzdušný", "zemní"]} nullable />
						</DetailItemRow>
						<Form.TextareaItem name={"poznamka"} label={"Poznámka"} mode={mode} />
					</div>
				</DetailCard>
			</DetailColumn>
			<DetailColumn>
				<DetailCard title="Statistiky">
					<div>
						<DetailItemRow>
							<Form.NumberItem name={"pocetLamp"} label={"Počet lamp"} mode={mode} readonly />
							<Form.NumberItem name={"pocetSvitidel"} label={"Počet svítidel"} mode={mode} readonly />
							<Form.NumberItem name={"celkovaSpotreba"} label={"Celková spotřeba"} mode={mode} unit={"w"}
											 readonly />
						</DetailItemRow>

					</div>
				</DetailCard>
				{qrCodes && qrSetFileName && (
					<DetailCard title="Sada QR kódů">
						<div style={{ paddingBottom: 10 }}>
							<QrCodePdfButton qrCodes={qrCodes} downloadFileName={qrSetFileName}>
								Stáhnout PDF s QR kódy lamp
							</QrCodePdfButton>
						</div>
					</DetailCard>
				)}
			</DetailColumn>
		</DetailRow>
	);
};
