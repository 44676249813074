import { MarkerPopup } from "@pasport/react-common";
import { Marker } from "maps-api";
import { MapZbData } from "../../../apis/switchPoint/SwitchPointApi.dto";
import { getAbsoluteRoutePath } from "../../../core/routing";

export default function SwitchPointPopup({ zb, opened, marker }: { zb: MapZbData; opened: boolean; marker: Marker }) {
	const url =
		getAbsoluteRoutePath("zapinaciBod", {
			zapinaciBodId: zb.id,
		}) + "/detail";
	return !opened ? <></> : <MarkerPopup title="Zapínací bod" marker={marker} detailUrl={url} items={[{ label: "Označení", value: zb.oznaceni }]} />;
}
