import { Button, DownloadIcon } from "@pasport/react-ui";
import ReactPDF, { usePDF } from "@react-pdf/renderer";
import { ReactNode, useEffect, useMemo, useState } from "react";
//import usePDF = ReactPDF.usePDF;
import { QrCodeDocument } from "./QrCodeDocument";


interface QrCodeItem {
	value: string,
	label?: string
}

interface QrCodePdfButtonProps {
	downloadFileName: string,
	qrCodes: QrCodeItem[],
	children: ReactNode
}


export const QrCodePdfButton = ({ qrCodes, downloadFileName, children }: QrCodePdfButtonProps) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [counter, setCounter] = useState(0);

	const pdfDocument = useMemo(() => {
		return <QrCodeDocument qrCodes={qrCodes} onLoadChange={(isLoaded) => {
			setIsLoaded(isLoaded);
			setCounter(counter + 1);
		}} />;
	}, [setIsLoaded, qrCodes]);

	const [instance, updateInstance] = usePDF({ document: pdfDocument });
	const [requestDownload, setRequestDownload] = useState(false);


	useEffect(() => {
		if (requestDownload && !instance.loading && isLoaded && instance.blob?.size && instance.blob?.size > 780) {
			(() => downloadPdf())();
		}
	}, [instance.loading, instance.blob, requestDownload, isLoaded]);


	const downloadPdf = async () => {
		const link = document.createElement("a");
		link.target = "_blank";
		link.href = instance.url ? instance.url : "";
		link.setAttribute(
			"download",
			downloadFileName,
		);

		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
		setRequestDownload(false);
	};

	const handleClick = () => {
		setRequestDownload(true);
	};


	return <Button color="dark" onPress={handleClick} icon={requestDownload ? (
		<div className="spinner-border spinner-border-sm" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
	) : <DownloadIcon />}>{children}</Button>;
};