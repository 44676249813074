import {
	apiToNumber,
	apiToString,
	mapApiToDiaryEntryAdvanced,
	DiaryEntryApiDto,
	DiaryEntryAdvancedDto, mapDiaryEntryToApi, DiaryEntryDto, mapApiToDiaryEntry,
} from "@pasport/react-common";
import { DiaryApiDto as VoDiaryEntryApiDto, DiaryEntryWithDescriptorApiDto } from "./DiaryApi.dto";

export type DiaryEntryVoAdvancedDetail = {
	zbId: number,
	oznaceniZB: string
	lampaId?: number,
	oznaceniLampy?: string
	vetevId?: number,
};

export type DiaryEntryWithVoDescriptorDto = DiaryEntryAdvancedDto<DiaryEntryVoAdvancedDetail>;

export const mapVOApiToDiaryEntryWithDescriptor = (response: VoDiaryEntryApiDto): DiaryEntryWithVoDescriptorDto => {

	return mapApiToDiaryEntryAdvanced(mapVOApiToCommonApi(response) as DiaryEntryWithDescriptorApiDto,
		(data) => ({
			zbId: apiToNumber(data.zb_id),
			oznaceniZB: apiToString(data.oznaceni_zb),
			vetevId: apiToNumber(data.vetev_id) || undefined,
			lampaId: apiToNumber(data.lampa_id) || undefined,
			oznaceniLampy: apiToString(data.oznaceni_lampy) || undefined,
		}),
	);
};

export const mapVOApiToDiaryEntry = (response: VoDiaryEntryApiDto): DiaryEntryDto => {
	return mapApiToDiaryEntry(mapVOApiToCommonApi(response));
};

export const mapVOApiToCommonApi = (response: VoDiaryEntryApiDto): DiaryEntryApiDto => {
	return {
		hlaseni_id: response.denikzb_id ?? response.denikvo_id,
		...response,
	};
};


export const mapDiaryEntryToVoApi = (diaryEntry: DiaryEntryDto, createMode: boolean) => {
	const { IXopraveno, DXdatum_hlaseni, ...request } = mapDiaryEntryToApi(diaryEntry, createMode);

	if (createMode) {
		return {
			datum_hlaseni: DXdatum_hlaseni,
			...request,
		};
	} else {

		return {
			opraveno: IXopraveno,
			datum_hlaseni: DXdatum_hlaseni,
			...request,
		};
	}
};