import { LAMP_COLUMN_TYPES, LampDto } from "../../interfaces";
import {
	apiToDate,
	apiToFloatNumber,
	apiToNumber,
	apiToPosition,
	apiToString,
	apiToStringEnum,
	convertInnerLocationToGPS,
	dateToApi,
	numberToApi,
	stringToApi,
} from "@pasport/react-common";
import { LampApiDto, MapLampData, MapLampDto } from "./LampApi.dto";

export const mapApiToLamp = (response: LampApiDto): LampDto => {
	return {
		id: apiToNumber(response.lampa_id),
		poznamka: apiToString(response.poznamka),
		datumPlanovaneRevize: apiToDate(response.datum_plan_revize),
		oznaceni: response.oznaceni
			? apiToString(response.oznaceni)
			: apiToString(response.oznaceni_lampy),
		pocetSvitidel: apiToNumber(response.pocet_svitidel),
		datumPosledniZavady: apiToDate(response.datum_posl_zavady),
		datumPosledniUdrzby: apiToDate(response.datum_posl_servisu),
		spotreba: apiToNumber(response.celkova_spotreba),
		umisteni: apiToString(response.umistneni),
		pocetVylozniku: apiToNumber(response.pocet_vylozniku),
		vyskaSloupu: apiToFloatNumber(response.vyska_sloupu_m),
		typSloupu: apiToStringEnum(response.typ_sloupu, LAMP_COLUMN_TYPES),

		oznaceniVetve: apiToString(response.oznaceni_vetve),
		oznaceniZB: apiToString(response.oznaceni_zb),
		castObce: apiToString(response.cast_obce),
		pozice: apiToPosition(response.st_x, response.st_y),
		hash: apiToString(response.substring),
	};
};

export const mapLampToApi = (lamp: LampDto) => {
	return {
		oznaceni: stringToApi(lamp.oznaceni),
		umistneni: stringToApi(lamp.umisteni),
		typ_sloupu: stringToApi(lamp.typSloupu),
		vyska_sloupu_m: numberToApi(lamp.vyskaSloupu),
		pocet_vylozniku: numberToApi(lamp.pocetVylozniku),
		DXdatum_plan_revize: dateToApi(lamp.datumPlanovaneRevize),
		poznamka: stringToApi(lamp.poznamka),
	};
};

export const mapApiToLampData = (lamp: MapLampDto): MapLampData => {
	const convert = convertInnerLocationToGPS;
	const x = parseFloat(lamp.st_x || "0");
	const y = parseFloat(lamp.st_y || "0");

	return {
		id: lamp.lampa_id,
		oznaceni: lamp.oznaceni_lampy,
		vetevId: lamp.vetev_id,
		oznaceniVetve: lamp.oznaceni_vetve,
		zbId: lamp.zb_id,
		oznaceniZb: lamp.oznaceni_zb,
		location: convert([x, y]),
	};
};
