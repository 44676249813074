import { DiaryEntryCreateForm, DiaryEntryDto, DiaryEntryType, useAuth } from "@pasport/react-common";


export const DIARY_ENTRY_TYPES_ANONYMOUS: DiaryEntryType[] = [DiaryEntryType.ZAVADA, DiaryEntryType.INFORMACE];

export const DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS: string[] = [
	"Blikající svítidlo",
	"Nesvítící svítidlo",
	"Rozbité svítidlo",
	"Chybějící patice",
	"Chybějící dvířka",
];

interface CreateDiaryEntryAnonymousProps {
	dispatchCreate: (values: DiaryEntryDto) => Promise<number>,
	onCreateSuccess: () => void,
	variant?: "lamp" | "switchPoint",
}

export const CreateDiaryEntryAnonymous = ({
											  dispatchCreate,
											  onCreateSuccess,
											  variant,
										  }: CreateDiaryEntryAnonymousProps) => {
	const { isLogged, activeLau } = useAuth();

	return (
		<div className={"row justify-content-center"}>
			<div className={"col-12 col-md-6"}>
				{isLogged && activeLau && (
					<div className="alert alert-primary" role="alert">
						<div>
							<strong>Anonymní režim nahlášení</strong>
						</div>
						<div>
							{variant === "lamp" ? "Tato lampa" : "Tento zapínací bod"} není v
							obci <span>{activeLau.obec}</span>, kterou právě spravujete.
						</div>
					</div>
				)}
				<DiaryEntryCreateForm

					dispatchCreate={(formValues) => dispatchCreate({ ...formValues })}
					onCreateSuccess={onCreateSuccess}
					textSuggestions={variant === "lamp" ? DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS : []}
					submitButtonVersion="bottom"
					fullSize
					diaryEntryTypeList={DIARY_ENTRY_TYPES_ANONYMOUS}

				/>
			</div>
		</div>
	);
};