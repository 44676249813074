import { Route, Routes } from "react-router-dom";
import { HomeScreen } from "../screens/HomeScreen";
import { SwitchPointAddScreen } from "../screens/SwitchPointAddScreen";
import React from "react";
import { useTownParts } from "../hooks/useTownParts";
import { SwitchPointRouter } from "./SwitchPointRouter";

import { Breadcrumbs, SkeletonLoader } from "@pasport/react-common";


export const AppRouter = () => {
	const { townPartsApiState } = useTownParts();
	return (
		<>
			<Breadcrumbs />
			<SkeletonLoader
				data={[townPartsApiState]}
				render={([townParts]) => (
					<Routes>
						<Route path="*" element={<HomeScreen />} />


						<Route path="zapinaci-bod/:zapinaciBodId/*" element={
							<SwitchPointRouter />
						} />
						<Route path={"pridat-zapinaci-bod"} element={<SwitchPointAddScreen />} />

					</Routes>
				)}
			/>
		</>
	);
};