import React from "react";
import "./core/config";
import {
	BrowserRouter as Router,
} from "react-router-dom";
import { store } from "./redux";
import { Provider } from "react-redux";
import { BaseRouter } from "./routers/BaseRouter";
import { PanoramaProvider } from "maps-api";

import {
	ActivityInfoProvider,
	AlertProvider,
	AuthProvider,
	BreadcrumbsProvider,
	KatastrProvider,
	Navbar,
	NavbarDropdownItem,
	RefreshTokenGuard,
} from "@pasport/react-common";
import { getAbsoluteRoutePath } from "./core/routing";


function App() {
	return (
		<Provider store={store}>
			<AuthProvider>
				<BreadcrumbsProvider>
					<AlertProvider>
						<KatastrProvider>
							<PanoramaProvider>
								<ActivityInfoProvider>
									<div className="h-100 d-flex flex-column">
										<Router>
											<Navbar
												title="Veřejné osvětlení"
												dropdownItems={(
													<NavbarDropdownItem
														href={getAbsoluteRoutePath("spravovatUdaje", {})}>
														Spravovat kontakty
													</NavbarDropdownItem>
												)}
											/>
											<div className="flex-grow-1">
												<RefreshTokenGuard>
													<BaseRouter />
												</RefreshTokenGuard>
											</div>
										</Router>
									</div>
								</ActivityInfoProvider>
							</PanoramaProvider>
						</KatastrProvider>
					</AlertProvider>
				</BreadcrumbsProvider>
			</AuthProvider>
		</Provider>
	);
}


export default App;
