import React, { useEffect, useState } from "react";
import { CreateDiaryEntryAuthorized } from "./CreateDiaryEntryAuthorized";
import { CreateDiaryEntryAnonymous } from "./CreateDiaryEntryAnonymous";

import { ApiResponse, DiaryEntryDto, Page, PageHeader, useAuth } from "@pasport/react-common";
import { useUrlParams } from "../../core/routing";


interface CreateDiaryEntryParametricScreenProps {
	dispatchCreateDiaryEntry: (formValues: DiaryEntryDto) => Promise<number>,
	dispatchUpdateDiaryEntryIsSolved: (parentDiaryEntryId: number, isSolved: boolean) => Promise<ApiResponse>,
	dispatchDiaryEntries: () => Promise<DiaryEntryDto[]>,
	variant: "lamp" | "switchPoint",
}

export const CreateDiaryEntryParametricScreen = ({
													 dispatchCreateDiaryEntry,
													 dispatchUpdateDiaryEntryIsSolved,
													 dispatchDiaryEntries,
													 variant,
												 }: CreateDiaryEntryParametricScreenProps) => {
	const { lauId, hash } = useUrlParams();
	const { isLogged: isUserLogged, activeLau, user, setActiveLau } = useAuth();

	useEffect(() => {
		if (isUserLogged && activeLau?.id !== lauId && user) {
			const relevantLau = user.lauList?.find(lau => lau.id === lauId);
			if (relevantLau)
				setActiveLau(relevantLau);
		}
	}, []);

	const [isSuccessSubmit, setIsSuccessSubmit] = useState<boolean>(false);

	const dispatchCreate = async (formValues: DiaryEntryDto, parentDiaryEntry?: DiaryEntryDto) => {
		const response = await dispatchCreateDiaryEntry(formValues);
		if (parentDiaryEntry) {
			await dispatchUpdateDiaryEntryIsSolved(parentDiaryEntry.id, parentDiaryEntry.opraveno);
		}
		return response;
	};

	const handleSuccess = () => {
		//addSuccessAlert({message: "Děkujeme Vám za přidané hlášení!"});
		setIsSuccessSubmit(true);
	};

	if (!hash) {
		return (
			<div className="text-center pt-5">
				<h3 className="fw-bold">Tato stránka je nedostupná.</h3>
				<div>Pravděpodobně jste otevřeli neplatný odkaz.</div>
			</div>
		);
	}

	return (
		<Page>
			{!isSuccessSubmit ? (
				<>

					<div className="pb-3" />

					<PageHeader title={"Nahlášení závady"} />
					{isUserLogged && activeLau?.id === lauId ? (
						<CreateDiaryEntryAuthorized
							dispatchCreate={dispatchCreate}
							onCreateSuccess={handleSuccess}
							dispatchDiaryEntries={dispatchDiaryEntries}
							variant={variant}
						/>
					) : (
						<CreateDiaryEntryAnonymous dispatchCreate={dispatchCreate} onCreateSuccess={handleSuccess}
												   variant={variant} />
					)}
				</>

			) : (
				<div className="p-3">
					<PageHeader title={"Děkujeme Vám!"} />
					<div className="text-center">Podání hlášení proběhlo úspěšně.</div>
				</div>
			)}


		</Page>
	);
};