import { LampDto } from "../../interfaces";
import { LampItem } from "./LampItem";
import { LAMP_SORT_METHOD, LampListHeader } from "./LampListHeader";
import { useState } from "react";
import {
	getFulltextSearch,
	SORT_DIRECTION,
	sortDates,
	sortNumbers,
	sortStrings,
	TabContent,
	TabEmptyContent,
} from "@pasport/react-common";

interface LampListProps {
	items: LampDto[],
}

function getLampSortingFunc(key: LAMP_SORT_METHOD, increasingOrder: boolean): (a: LampDto, b: LampDto) => number {
	switch (key) {
		case LAMP_SORT_METHOD.POCET_SVITIDEL:
			return (a, b) => sortNumbers(a.pocetSvitidel, b.pocetSvitidel, increasingOrder);
		case LAMP_SORT_METHOD.SPOTREBA:
			return (a, b) => sortNumbers(a.spotreba, b.spotreba, increasingOrder);
		case LAMP_SORT_METHOD.DATUM_POSL_ZAVADY:
			return (a, b) => sortDates(a.datumPosledniZavady, b.datumPosledniZavady, increasingOrder);
		case LAMP_SORT_METHOD.DATUM_POSL_UDRZBY:
			return (a, b) => sortDates(a.datumPosledniUdrzby, b.datumPosledniUdrzby, increasingOrder);
		case LAMP_SORT_METHOD.DATUM_PLANOVANE_REVIZE:
			return (a, b) => sortDates(a.datumPlanovaneRevize, b.datumPlanovaneRevize, increasingOrder);
		case LAMP_SORT_METHOD.OZNACENI:
		default:
			return (a, b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder);
	}
}

const sortBySortMethod = (sortMethod: LAMP_SORT_METHOD, items: LampDto[], sortDirection: SORT_DIRECTION) => {
	const sortingFunc = getLampSortingFunc(sortMethod, sortDirection === SORT_DIRECTION.UP);
	return items.slice().sort((a, b) => sortingFunc(a, b));
};

export const LampList = ({ items }: LampListProps) => {
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortMethod, setSortMethod] = useState<LAMP_SORT_METHOD>(LAMP_SORT_METHOD.OZNACENI);
	const itemsFulltext = getFulltextSearch(searchQuery, items, item => [item.oznaceni]);
	const itemsSorted = sortBySortMethod(sortMethod, itemsFulltext, sortDirection);

	return items.length > 0 ? (
		<TabContent>
			<LampListHeader
				onTextChange={setSearchQuery}
				onSortMethodChange={(value) => setSortMethod(value as LAMP_SORT_METHOD)}
				setSortDirection={setSortDirection}
			/>
			{itemsSorted.length > 0 ? itemsSorted.map((item) => (
				<div className={"pb-2"} key={item.id}>
					<LampItem value={item} />
				</div>
			)) : "Filtrům neodpovídá žádná lampa."}
		</TabContent>
	) : <TabEmptyContent text="Zatím zde není žádná lampa." />;
};