import QRCode from "qrcode";
import {QrCodeItem} from "./QrCodeItem";

export interface GetQrCodeImageReturn {
    imageUrl: string,
    width: number,
    height: number
}

export const getQrCodeImage = async ({value, label, description} : QrCodeItem) : Promise<GetQrCodeImageReturn> => {

    const scale = 3;
    const qrWidth = 200*scale;
    const qrHeight = 200*scale;

    const spaceAround = 40* scale;
    const extraLabelSpace = 40* scale;


    const extraNameSpace = label ? extraLabelSpace : 0;
    const extraDescriptionSpace = description ? extraLabelSpace : 0;

    const qrCanvas = document.createElement("canvas") as HTMLCanvasElement;

    qrCanvas.width = qrWidth;
    qrCanvas.height = qrHeight;
    await QRCode.toCanvas(qrCanvas, value, {width:qrWidth, margin:0} );

    const qrCtx = qrCanvas.getContext("2d") as CanvasRenderingContext2D;
    const qrImg = qrCtx.getImageData(0,0, qrCanvas.width,qrCanvas.height);

    //combine canvases and label
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const realWidth = qrWidth + spaceAround*2;
    const realHeight = qrHeight + spaceAround*2 + extraDescriptionSpace + extraNameSpace;
    canvas.width = realWidth;
    canvas.height = realHeight;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.beginPath();
    ctx.fillStyle = "white";
    ctx.fillRect(0,0,realWidth,realHeight)
    ctx.fill();

    ctx.putImageData(qrImg,spaceAround,(spaceAround+extraDescriptionSpace)  )

    if(description){
        ctx.font = `italic ${18*scale}px "Segoe UI"`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "alphabetic";
        ctx.fillText(description , realWidth/2, (spaceAround+extraDescriptionSpace)/1.7);
    }

    if(label){
        ctx.font = `bold ${18*scale}px "Segoe UI"`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "alphabetic";
        ctx.fillText(label , realWidth/2, extraDescriptionSpace + qrHeight + spaceAround + (spaceAround + extraNameSpace)/1.7);
    }

    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    console.log("first in on");
    return {
        imageUrl:pngUrl,
        width: realWidth,
        height: realHeight
    }
}