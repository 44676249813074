import { MapObject, MarkerLayer, useApiStateEffect, useAuth } from "@pasport/react-common";
import { getMapLamps } from "../../apis/lamp";
import { MapLampData } from "../../apis/lamp/LampApi.dto";
import { getMapSwitchPoints } from "../../apis/switchPoint";
import { MapZbData } from "../../apis/switchPoint/SwitchPointApi.dto";
import Lamp from "../../screens/MapScreen/components/LampMarker";
import SwitchPointMarker from "../../screens/MapScreen/components/SwitchPointMarker";
import { getBranchColor } from "./useBranchColor";

type MapBranchData = {
	id: number;
	oznaceni: string;
	color: string;
};
type LampStateType = {
	lamps: MapLampData[];
	switchPoints: MapZbData[];
};
export const useLampsAndSwitchPoints = () => {
	const { activeLau } = useAuth();

	const [state] = useApiStateEffect<LampStateType>(async () => {
		return {
			lamps: await getMapLamps(activeLau?.id ?? 0),
			switchPoints: await getMapSwitchPoints(activeLau?.id ?? 0),
		};
	}, []);

	const addToMap = (map: MapObject, data: LampStateType) => {
		const zbs: Record<
			number,
			{
				layer: MarkerLayer;
				branches: Record<number, { layer: MarkerLayer; data: MapBranchData }>;
				data: MapZbData;
			}
		> = [];
		data.lamps.forEach((lamp) => {
			const zbId = lamp.zbId;
			zbs[zbId] = zbs[zbId] || {
				layer: map.createLayer(),
				branches: {},
				data: {
					id: lamp.zbId,
					oznaceni: lamp.oznaceniZb,
				},
			};

			const branchId = lamp.vetevId;
			if (!zbs[zbId].branches[branchId]) {
				const branchLayer = map.createLayer();
				zbs[zbId].layer.add(branchLayer);

				zbs[zbId].branches[branchId] = {
					layer: branchLayer,
					data: {
						id: lamp.vetevId,
						oznaceni: lamp.oznaceniVetve,
						color: getBranchColor(lamp.vetevId),
					},
				};
			}
		});

		data.switchPoints.forEach((zb) => {
			if (!zbs[zb.id]) {
				zbs[zb.id] = {
					layer: map.createLayer(),
					branches: {},
					data: zb,
				};
			}
			zbs[zb.id].data = zb;
		});

		const layers = {
			// Create switchPointLayers dictionary with keys as branch ids and values as new layers
			switchPoints: zbs,
		};

		// Add markers to the map
		data.lamps.map((lamp) => {
			const marker = map.createMarker(lamp.location, (marker, leafletMap) => (
				<Lamp lamp={lamp} map={map} marker={marker} />
			));
			layers.switchPoints[lamp.zbId].branches[lamp.vetevId].layer.add(marker);
		});

		data.switchPoints.forEach((zb) => {
			const marker = map.createMarker(zb.location, (marker, leafletMap) => (
				<SwitchPointMarker data={zb} marker={marker} />
			));
			layers.switchPoints[zb.id].layer.add(marker);
		});

		//Initialize layers
		Object.values(layers.switchPoints).forEach((layer) => {
			layer.layer.initialize();
			Object.values(layer.branches).forEach((branchLayer) => {
				branchLayer.layer.initialize();
			});
		});

		return layers;
	};
	return {
		state,
		addToMap,
	};
};
