import {LampBranchDto} from "../../interfaces/LampBranchDto";
import {LampBranchItem} from "./LampBranchItem";
import {LAMP_BRANCH_SORT_METHOD, LampBranchListHeader  } from "./LampBranchListHeader";
import {useState} from "react";
import {getFulltextSearch, SORT_DIRECTION, sortNumbers, sortStrings, TabContent, TabEmptyContent} from "@pasport/react-common";

interface LampBranchListProps {
    items: LampBranchDto[]
}

function getLampSortingFunc (key : LAMP_BRANCH_SORT_METHOD, increasingOrder: boolean) : (a: LampBranchDto, b: LampBranchDto) => number {
    switch(key){
        case LAMP_BRANCH_SORT_METHOD.POCET_SVITIDEL:
            return (a , b) => sortNumbers(a.pocetSvitidel, b.pocetSvitidel, increasingOrder);
        case LAMP_BRANCH_SORT_METHOD.SPOTREBA:
            return (a , b) => sortNumbers(a.celkovaSpotreba, b.celkovaSpotreba, increasingOrder);
        case LAMP_BRANCH_SORT_METHOD.OZNACENI:
        default:
            return (a , b) => sortStrings(a.oznaceni, b.oznaceni, increasingOrder)
    }
}

const sortBySortMethod = (sortMethod : LAMP_BRANCH_SORT_METHOD, items: LampBranchDto[], sortDirection: SORT_DIRECTION) => {
    const sortingFunc = getLampSortingFunc(sortMethod, sortDirection === SORT_DIRECTION.UP);
    return items.slice().sort((a , b) => sortingFunc(a, b))
}

export const LampBranchList = ({items} : LampBranchListProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [sortMethod, setSortMethod] = useState<LAMP_BRANCH_SORT_METHOD>(LAMP_BRANCH_SORT_METHOD.OZNACENI);
    const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(SORT_DIRECTION.UP);

    const itemsFulltext = getFulltextSearch(searchQuery, items, item => [item.oznaceni])
    const itemsSorted = sortBySortMethod(sortMethod, itemsFulltext, sortDirection)


    return (
        items.length > 0 ? (
            <TabContent>
                <LampBranchListHeader onTextChange={setSearchQuery} setSortDirection={setSortDirection} setSortMethod={setSortMethod}/>
                {itemsSorted.length > 0 ? itemsSorted.map((item) => (
                    <div key={item.id} className={"pb-2"}>
                        <LampBranchItem value={item}/>
                    </div>
                )) : "Filtrům neodpovídá žádná větev"}
            </TabContent>
        ) : <TabEmptyContent text={"Zatím zde není žádná větev veřejného osvětlení."} />
    )
}