import { useEffect, useState } from "react";

export const useShowMapLabels = () => {
	const onEvent = new Event("turnOnOznaceniEvent");
	const offEvent = new Event("turnOffOznaceniEvent");

	const [showLabels, setShowLabels] = useState(false);

	const turnOn = () => {
		window.dispatchEvent(onEvent);
	};

	const turnOff = () => {
		window.dispatchEvent(offEvent);
	};

	useEffect(() => {
		window.addEventListener(onEvent.type, () => setShowLabels(true));
		window.addEventListener(offEvent.type, () => setShowLabels(false));

		return () => {
			window.removeEventListener(onEvent.type, () => setShowLabels(true));
			window.removeEventListener(offEvent.type, () => setShowLabels(false));
		};
	}, []);

	return {
		turnOn,
		turnOff,
		isOn: showLabels,
	};
};
