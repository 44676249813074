import { LampDto } from "../../interfaces";
import { ListItem, Row, RowItem } from "@pasport/react-ui";
import { formatDate } from "@pasport/react-common";

interface LampItemProps {
	value: LampDto;
}

export const LampItem = ({ value }: LampItemProps) => {
	return (
		<ListItem link={"../lampa/" + value.id} icon={(
			<span className="fs-2">
                <img src={`${process.env.PUBLIC_URL}/lamp_icon.png`} width={40} />
            </span>
		)}>
			<Row>
				<RowItem label={"Lampa"} size={2} priority={10}>{value.oznaceni}</RowItem>
				<RowItem label={"Počet svítidel"} size={1.5}>{value.pocetSvitidel}</RowItem>
				<RowItem label={"Spotřeba"} size={1}>{value.spotreba} w</RowItem>
				<RowItem label={"Plánovaná revize"} size={1.5}>{formatDate(value.datumPlanovaneRevize)}</RowItem>
				<RowItem label={"Poslední údržba"} size={1.5}>{formatDate(value.datumPosledniUdrzby)}</RowItem>
				<RowItem label={"Poslední závada"} size={1.5}>{formatDate(value.datumPosledniZavady)}</RowItem>
				<RowItem label={"Poznámka"} size={3} priority={9}>{value.poznamka}</RowItem>
			</Row>
		</ListItem>
	);
};