import { useParams } from "react-router-dom";
import { RouterParamName } from "./routerPaths";

interface UseUrlParams {
	zapinaciBodId: number,
	zbHlaseniId: number,
	vetevId: number,
	lampaId: number,
	lampaHlaseniId: number,
	svitidloId: number,
	lauId: number,
	hash: string
}

export const useUrlParams = (): UseUrlParams => {
	const {
		zapinaciBodId,
		zbHlaseniId,
		vetevId,
		lampaId,
		lampaHlaseniId,
		svitidloId,
		lau,
		hash,
	} = useParams<RouterParamName>();
	return {
		zapinaciBodId: Number(zapinaciBodId),
		zbHlaseniId: Number(zbHlaseniId),
		vetevId: Number(vetevId),
		lampaId: Number(lampaId),
		lampaHlaseniId: Number(lampaHlaseniId),
		svitidloId: Number(svitidloId),
		lauId: Number(lau),
		hash: hash || "",
	};
};