import { CLOSE_ALL_POPUPS_EVENT } from "@pasport/react-common";
import React, { useEffect } from "react";
import SwitchPointPopup from "./SwitchPointPopup";
import { MapZbData } from "../../../apis/switchPoint/SwitchPointApi.dto";
import { Marker } from "maps-api";

type Props = {
	data: MapZbData;
	marker: Marker;
};

export default function SwitchPointMarker(props: Props) {
	const [popupOpened, setPopupOpened] = React.useState(false);

	const onClick = () => {
		const opened = !popupOpened;
		window.dispatchEvent(new Event(CLOSE_ALL_POPUPS_EVENT));
		setPopupOpened(opened);
	};

	useEffect(() => {
		const close = () => {
			setPopupOpened(false);
		};

		window.addEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		return () => {
			window.removeEventListener(CLOSE_ALL_POPUPS_EVENT, close);
		};
	}, []);

	const iconSize = 30;
	return (
		<div onClick={onClick}>
			<img
				src={"icons/zb.png"}
				width={iconSize}
				height={iconSize}
				style={{
					position: "absolute",
					// left: "50%",
					// top: "50%",
					transform: "translate(-50%, -50%)",
				}}
			/>
			<SwitchPointPopup zb={props.data} opened={popupOpened} marker={props.marker} />
		</div>
	);
}
