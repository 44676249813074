import { SearchInput, Select, SelectOption } from "@pasport/react-ui";
import { SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS, useWindowSize } from "@pasport/react-common";


export enum LAMP_SORT_METHOD {
	OZNACENI = "OZNACENI",
	POCET_SVITIDEL = "POCET_SVITIDEL",
	SPOTREBA = "SPOTREBA",
	DATUM_POSL_ZAVADY = "DATUM_POSL_ZAVADY",
	DATUM_POSL_UDRZBY = "DATUM_POSL_UDRZBY",
	DATUM_PLANOVANE_REVIZE = "DATUM_PLANOVANE_REVIZE",
}

const LAMP_SORT_OPTIONS: SelectOption[] = [
	{ value: LAMP_SORT_METHOD.OZNACENI, label: "Označení" },
	{ value: LAMP_SORT_METHOD.POCET_SVITIDEL, label: "Počet svítidel" },
	{ value: LAMP_SORT_METHOD.SPOTREBA, label: "Spotřeba wattů" },
	{ value: LAMP_SORT_METHOD.DATUM_POSL_ZAVADY, label: "Datum poslední hlášené závady" },
	{ value: LAMP_SORT_METHOD.DATUM_POSL_UDRZBY, label: "Datum poslední údržby" },
	{ value: LAMP_SORT_METHOD.DATUM_PLANOVANE_REVIZE, label: "Datum plánované revize" },
];


interface LampListHeaderProps {
	onTextChange: (searchQuery: string) => void,
	onSortMethodChange: (sortMethod: string) => void,
	setSortDirection: (sortMethod: SORT_DIRECTION) => void,
}

export const LampListHeader = ({ onSortMethodChange, onTextChange, setSortDirection }: LampListHeaderProps) => {
	const { isMobile } = useWindowSize();

	const handleDirection = (value: string) => {
		setSortDirection(value as SORT_DIRECTION);
	};

	return (
		<div className="d-flex align-items-end justify-content-between mb-2 flex-wrap gap-2">
			<div className="d-flex align-items-center gap-3 flex-grow-1">
				<div style={{ width: "100%", maxWidth: isMobile ? "100%" : 300 }}>
					<SearchInput placeholder={"Hledat lampu dle označení"} onTextChange={onTextChange} />
				</div>
			</div>

			<div className="d-flex gap-2 align-items-center ms-auto">
				<span className="text-nowrap">Seřadit podle:</span>
				<div style={{ width: "100%", maxWidth: 300 }}>
					<Select
						onChange={onSortMethodChange}
						options={LAMP_SORT_OPTIONS}
					/>
				</div>
				<div style={{ width: "100%", maxWidth: 130 }}>
					<Select
						onChange={handleDirection}
						options={SORT_DIRECTION_SELECT_OPTIONS}
					/>
				</div>
			</div>
		</div>
	);
};