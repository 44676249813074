import { LIGHT_SOURCE_TYPES, LightDto } from "../../interfaces/LightDto";
import { apiToNumber, apiToString, apiToStringEnum, numberToApi, stringToApi } from "@pasport/react-common";
import { LightApiDto } from "./LightApi.dto";

export const mapApiToLight = (response: LightApiDto): LightDto => {
	return {
		id: apiToNumber(response.svitidlo_id),
		typ: apiToString(response.typ_svitidla),
		spotreba: apiToNumber(response.pocet_wattu),
		svetelnyZdroj: apiToStringEnum(response.svetelny_zdroj, LIGHT_SOURCE_TYPES) ?? LIGHT_SOURCE_TYPES [0],
		poznamka: apiToString(response.poznamka),
	};
};

export const mapLightToApi = (light: LightDto) => {
	return {
		typ_svitidla: stringToApi(light.typ),
		svetelny_zdroj: stringToApi(light.svetelnyZdroj),
		pocet_wattu: numberToApi(light.spotreba),
		poznamka: stringToApi(light.poznamka),
	};
};