import { LightCards } from "./LightCards";
import { LightDto } from "../../interfaces/LightDto";
import { useNavigate } from "react-router-dom";
import { FormMode, FormWithCreate, useAlert } from "@pasport/react-common";
import { getAbsoluteRoutePath } from "../../core/routing";
import { createLight } from "../../apis/light";


const defaultLight: LightDto = {
	id: -1,
	typ: "",
	spotreba: 0,
	svetelnyZdroj: "LED",
	poznamka: "",
};

interface LightCreateFormProps {
	lampId: number,
	lampBranchId: number,
	switchPointId: number,
	onCreate?: () => void
}

export const LightCreateForm = ({ lampId, lampBranchId, switchPointId, onCreate }: LightCreateFormProps) => {

	const navigate = useNavigate();
	const { addSuccessAlert } = useAlert();
	return (
		<FormWithCreate
			initValues={defaultLight}
			dispatchCreate={(value) => {
				return createLight(value, lampId, lampBranchId, switchPointId);
			}}
			onCreateSuccess={(value: number) => {
				addSuccessAlert({ message: "Svítidlo bylo přidáno." });
				navigate(
					getAbsoluteRoutePath("lampa", {
							lampaId: lampId,
							vetevId: lampBranchId,
							zapinaciBodId: switchPointId,
						},
					));
				if (onCreate) onCreate();
			}}
		>
			{() => <LightCards mode={FormMode.EDIT} />}
		</FormWithCreate>
	);

};

