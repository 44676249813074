import { useAppDispatch, useAppSelector } from "../redux";
import { TownPartDto } from "../interfaces";
import { setTownParts } from "../redux/slices/townPartSlice";
import { useEffect } from "react";
import { ApiState, isApiStateDispatched, useApiState } from "@pasport/react-common";
import { getTownPartList } from "../apis/townPart/TownPartApis";

interface UseTownParts {
	townParts: TownPartDto[],
	townPartsApiState: ApiState<TownPartDto[]>
}

export const useTownParts = (): UseTownParts => {
	const townPartsRedux = useAppSelector(state => state.townPart.townParts);
	const dispatch = useAppDispatch();

	const { fetchApiState, apiState } = useApiState<TownPartDto[]>({
		getter: () => townPartsRedux,
		setter: (value) => dispatch(setTownParts(value)),
	});

	useEffect(() => {
		if (!isApiStateDispatched(apiState))
			fetchApiState(() => getTownPartList());
	}, [apiState]);

	return {
		townPartsApiState: apiState,
		townParts: apiState.data ?? [],
	};
};