import { LampLightCard } from "../components/Lamp/LampLightCard";

import { AddIcon, DetailIcon, DiaryIcon } from "@pasport/react-ui";
import {
	DiaryEntryCreateForm, DiaryEntryDto, DiaryList, isApiStateDispatched,
	Page,
	PageHeader,
	SkeletonLoader,
	TabContent,
	TabRoutes,
	useActiveLau, useAlert,
	useApiState,
} from "@pasport/react-common";
import { useNavigate } from "react-router-dom";
import { LampCardsUpdate } from "../components/Lamp/LampCardsUpdate";
import { LightCreateForm } from "../components/Light/LightCreateForm";
import { LightDto } from "../interfaces/LightDto";
import { LampDto } from "../interfaces";
import { useEffect } from "react";
import { DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS } from "./DiaryEntryQrPointScreen/CreateDiaryEntryAnonymous";
import { getAbsoluteRoutePath, useUrlParams } from "../core/routing";
import { getQrCodeItemForLamp } from "../tech";
import { createDiaryEntryForLamp, getDiaryEntriesInLamp, getLightListInLamp } from "../apis/lamp";


interface LampScreenProps {
	lampDetail: LampDto,
	invalidateDetail: () => void,
}


export const LampScreen = ({ lampDetail, invalidateDetail }: LampScreenProps) => {
	const { activeLau } = useActiveLau();
	const navigate = useNavigate();
	const { lampaId, vetevId, zapinaciBodId } = useUrlParams();

	const qrCodeItem = activeLau ? getQrCodeItemForLamp(lampDetail, zapinaciBodId, vetevId, activeLau.id) : undefined;
	const { addSuccessAlert } = useAlert();

	const {
		fetchApiState: dispatchDiaryEntries,
		apiState: diaryEntriesApiState,
		invalidateApiState: invalidateDiaryEntries,
	} = useApiState<DiaryEntryDto[]>();
	const {
		fetchApiState: dispatchLightList,
		apiState: lightListApiState,
		invalidateApiState: invalidateLightList,
	} = useApiState<LightDto[]>();


	useEffect(() => {
		if (!isApiStateDispatched(diaryEntriesApiState))
			dispatchDiaryEntries(() => getDiaryEntriesInLamp(lampaId, vetevId, zapinaciBodId));
	}, [diaryEntriesApiState]);

	useEffect(() => {
		if (!isApiStateDispatched(lightListApiState))
			dispatchLightList(() => getLightListInLamp(lampaId, vetevId, zapinaciBodId));
	}, [lightListApiState]);


	return (
		<Page>
			<>
				<PageHeader title={"Lampa " + lampDetail.oznaceni} />
				<TabRoutes links={[
					{
						to: "detail",
						label: "Detail lampy",
						icon: <DetailIcon />,
						content: (
							<TabContent>
								<LampCardsUpdate
									lamp={lampDetail}
									lampBranchId={vetevId}
									switchPointId={zapinaciBodId}
									qrCodeItem={qrCodeItem}
									onUpdate={() => invalidateDetail()}
									lightCardRender={(
										<LampLightCard
											lightListApiState={lightListApiState}
											lampId={lampaId}
											lampBranchId={vetevId}
											switchPointId={zapinaciBodId}
											onUpdate={invalidateLightList}
										/>
									)}
								/>
							</TabContent>
						),
					}, {
						to: "denik",
						label: "Deník lampy",
						icon: <DiaryIcon />,
						content: (
							<SkeletonLoader
								data={[diaryEntriesApiState]}
								render={([diaryEntryList]) => (
									<DiaryList items={diaryEntryList} />
								)}
							/>
						),
					}]}
						   actionLinks={[{
							   to: "pridat-svitidlo",
							   label: "Přidat svítidlo",
							   icon: <AddIcon />,
							   content: (
								   <TabContent>
									   <LightCreateForm
										   lampId={lampaId}
										   lampBranchId={vetevId}
										   switchPointId={zapinaciBodId}
										   onCreate={() => invalidateLightList()}
									   />
								   </TabContent>
							   ),
						   }, {
							   to: "pridat-denikovy-zaznam",
							   label: "Přidat deníkový záznam",
							   icon: <AddIcon />,
							   content: (
								   <TabContent>
									   <DiaryEntryCreateForm
										   dispatchCreate={(formValues) => createDiaryEntryForLamp(formValues, lampaId, vetevId, zapinaciBodId)}
										   onCreateSuccess={(diaryEntryId: number) => {
											   addSuccessAlert({ message: "K lampě bylo přidáno deníkové hlášení." });
											   navigate(
												   getAbsoluteRoutePath("lampaHlaseni", {
													   zapinaciBodId,
													   vetevId,
													   lampaId,
													   lampaHlaseniId: diaryEntryId,
												   }),
											   );
											   invalidateDiaryEntries();
										   }}
										   textSuggestions={DIARY_ENTRY_LAMP_TEXT_SUGGESTIONS}
									   />
								   </TabContent>
							   ),
						   }]}
				/>
			</>
		</Page>
	);
};