import { SwitchPointDto } from "../../interfaces";
import {
	booleanToApi,
	DiaryEntryDto,
	fetchCreateEntity,
	fetchDeleteEntity,
	fetchEntityDetail,
	fetchEntityList,
	fetchPostAction,
	fetchUpdateEntity,
	fetchUploadFile,
	FileDto,
	FileUploadDto,
	getPasportApiUrl,
	mapApiToPhoto,
	mapPositionToRealJTSK,
	numberToApi,
	PositionDto,
} from "@pasport/react-common";
import {
	mapDiaryEntryToVoApi,
	mapVOApiToDiaryEntry,
	mapVOApiToDiaryEntryWithDescriptor,
} from "../../overrides/diary";
import { mapApiToSwitchPoint, mapApiToZbData, mapSwitchPointToApi } from "./SwitchPointApi.map";
import { mapApiToLampBranch } from "../lampBranch/LampBranchApi.map";
import { MapZbData, MapZbDto } from "./SwitchPointApi.dto";

//objekt zapinaciho bodu

export const createSwitchPoint = async (switchPoint: SwitchPointDto) => {
	return fetchCreateEntity("insertZapBod", {
		...mapSwitchPointToApi(switchPoint),
	}).then(async (switchPointId) => {
		if (switchPoint.pozice) await updateSwitchPointPosition(switchPoint.pozice, switchPointId);
		return switchPointId;
	});
};

export const getSwitchPointDetail = async (switchPointId: number) => {
	return fetchEntityDetail(`zap/detail/${switchPointId}`, mapApiToSwitchPoint);
};

export const updateSwitchPointDetail = async (switchPoint: SwitchPointDto) => {
	return fetchUpdateEntity("updateZapBodData", {
		...mapSwitchPointToApi(switchPoint),
		idZapBod: numberToApi(switchPoint.id),
	}).then(() => updateSwitchPointPosition(switchPoint.pozice, switchPoint.id));
};

export const getSwitchPointList = async () => {
	return fetchEntityList("zap/seznam", mapApiToSwitchPoint);
};

export const deleteSwitchPoint = async (switchPointId: number) => {
	return fetchDeleteEntity("deleteZapBod", {
		ZapBod: switchPointId,
	});
};

//fotografie zapinaciho bodu
export const getSwitchPointPhotoList = async (switchPointId: number) => {
	return fetchEntityList(`/zap/images/${switchPointId}`, mapApiToPhoto);
};

//prostorova informace
export const updateSwitchPointPosition = (
	position: PositionDto | undefined,
	switchPointId: number
) => {
	const pos =
		position?.x && position?.y
			? mapPositionToRealJTSK(position.x, position.y)
			: { x: null, y: null };
	return fetchPostAction("updateZapBodSPI", {
		st_x: numberToApi(pos.x),
		st_y: numberToApi(pos.y),
		zb_id: switchPointId,
		DXgdb_geomattr_data: "",
		DXkod1: "",
		DXkod2: "",
		DXtext1: "",
		DXtext2: "",
	});
};

//vetve zapinaciho bodu

export const getLampBranchListInSwitchPoint = async (switchPointId: number) => {
	return fetchEntityList(`vet/seznam/${switchPointId}`, mapApiToLampBranch);
};

//deník zapínacího bodu
export const getDiaryEntryListInSwitchPoint = async (switchPointId: number) => {
	return fetchEntityList(`zbhlas/seznam/${switchPointId}`, mapVOApiToDiaryEntry);
};

export const getChildrenOfDiaryEntryListForSwitchPoint = async (
	parentDiaryEntryId: number,
	switchPointId: number
): Promise<DiaryEntryDto[]> => {
	return fetchEntityList(
		`zbhlas/seznamHlaseni/${switchPointId}/${parentDiaryEntryId}`,
		mapVOApiToDiaryEntry
	);
};

export const createDiaryEntryForSwitchPoint = async (
	diaryEntry: DiaryEntryDto,
	switchPointId: number,
	lauId?: number
) => {
	return fetchCreateEntity(
		"insertZapBodHlaseni",
		{
			...mapDiaryEntryToVoApi(diaryEntry, true),
			zb_id: switchPointId,
		},
		undefined,
		lauId ? { activeLau: lauId } : undefined
	);
};

export const updateIsSolvedDiaryEntryForSwitchPoint = async (
	diaryEntryId: number,
	switchPointId: number,
	isSolved: boolean
) => {
	return fetchUpdateEntity("updateZBHlaseniZaskrtnuti", {
		opraveno: booleanToApi(isSolved),
		denik: diaryEntryId,
		zapBod: switchPointId,
	});
};

export const getDiaryEntryDetailForSwitchPoint = async (
	diaryEntryId: number,
	switchPointId: number
) => {
	return fetchEntityDetail(
		`zbhlas/detail/${switchPointId}/${diaryEntryId}`,
		mapVOApiToDiaryEntry
	);
};

//zapinaci bod | hlaseni | fotografie

export const getDiaryEntryPhotoListForSwitchPoint = async (
	diaryEntryId: number,
	switchPointId: number
) => {
	return fetchEntityList(`zbhlas/seznamIMG/${switchPointId}/${diaryEntryId}`, mapApiToPhoto);
};

export const uploadSwitchPointDiaryEntryPhoto = async (
	upload: FileUploadDto,
	diaryEntryId: number
) => {
	return fetchUploadFile(upload, {
		denikzb_id: diaryEntryId,
		case: "ZBHlaseni",
	});
};

export const deleteSwitchPointDiaryEntryPhoto = async (
	file: FileDto,
	diaryEntryId: number,
	switchPointId: number
) => {
	return fetchDeleteEntity("deleteFileZBHlaseni", {
		ZapBod: switchPointId,
		denikzb_id: diaryEntryId,
		attach_id: file.attach_id,
	});
};

//zapinaci bod | hlaseni

export const updateDiaryEntryForSwitchPoint = async (
	diaryEntry: DiaryEntryDto,
	switchPointId: number
) => {
	return fetchUpdateEntity("updateZBHlaseni", {
		...mapDiaryEntryToVoApi(diaryEntry, false),
		denikzb_id: diaryEntry.id,
		zapBod: switchPointId,
	});
};

export const deleteDiaryEntryForSwitchPoint = async (
	diaryEntryId: number,
	switchPointId: number
) => {
	return fetchDeleteEntity("deleteZBHlaseni", {
		denikzb_id: diaryEntryId,
		ZapBod: switchPointId,
	});
};

//overall diaries
export const getAllDiaryEntriesForSwitchPoints = async () => {
	return fetchEntityList(`zbhlas/seznamHlaseniObce`, mapVOApiToDiaryEntryWithDescriptor);
};

//switch point photo

export const uploadSwitchPointPhoto = async (upload: FileUploadDto, switchPointId: number) => {
	return fetchUploadFile(upload, {
		PXname: "jmeno",
		ZapBod: switchPointId + "",
		case: "ZapinaciBod",
	});
};

export const deleteSwitchPointPhoto = async (file: FileDto, switchPointId: number) => {
	return fetchDeleteEntity("deleteFileZB", {
		attach_id: file.attach_id,
		ZapBod: switchPointId,
	});
};

export const getMapSwitchPoints = async (lauId: number) => {
	return fetchPostAction(
		"mapZBObce",
		{
			submethod: "map",
			lau: lauId,
		},
		getPasportApiUrl("fce/select.php")
	)
		.then((response) => response.data)
		.then((data: MapZbDto[]) => data.map(mapApiToZbData));
};
