import { Button, ButtonGroup, SearchInput, Select, SelectOption } from "@pasport/react-ui";
import { SORT_DIRECTION, SORT_DIRECTION_SELECT_OPTIONS } from "@pasport/react-common";


interface DiaryListHeaderProps {
	onTextChange: (searchQuery: string) => void,
	activeEntryType: string,
	onActiveEntryTypeChange: (type: string) => void,
	sortDirection: SORT_DIRECTION,
	setSortDirection: (sortMethod: SORT_DIRECTION) => void,
	activeSearchType?: SEARCH_SELECT_OPTION,
	onActiveSearchTypeChange?: (type: string) => void,
	variant?: "lamp" | "switchPoint"
}

export enum DIARY_ENTRY_SORT_METHOD {
	DATUM = "DATUM",
}

export enum SEARCH_SELECT_OPTION {
	SMART = "Chytré hledání",
	AUTHOR = "Autor hlášení",
	SWITCH_POINT = "Zapínací bod",
	LAMP = "Lampa",
}

export const SEARCH_SELECT_OPTIONS_LAMP: SelectOption[] = [
	{ value: SEARCH_SELECT_OPTION.SMART, label: SEARCH_SELECT_OPTION.SMART },
	{ value: SEARCH_SELECT_OPTION.AUTHOR, label: SEARCH_SELECT_OPTION.AUTHOR },
	{ value: SEARCH_SELECT_OPTION.SWITCH_POINT, label: SEARCH_SELECT_OPTION.SWITCH_POINT },
	{ value: SEARCH_SELECT_OPTION.LAMP, label: SEARCH_SELECT_OPTION.LAMP },
];


export const SEARCH_SELECT_OPTIONS_SWITCH_POINT: SelectOption[] = [
	{ value: SEARCH_SELECT_OPTION.SMART, label: SEARCH_SELECT_OPTION.SMART },
	{ value: SEARCH_SELECT_OPTION.AUTHOR, label: SEARCH_SELECT_OPTION.AUTHOR },
	{ value: SEARCH_SELECT_OPTION.SWITCH_POINT, label: SEARCH_SELECT_OPTION.SWITCH_POINT },
];

export const MAP_SEARCH_PLACEHOLDER: Record<SEARCH_SELECT_OPTION, string> = {
	[SEARCH_SELECT_OPTION.AUTHOR]: "Hledat dle autora hlášení ...",
	[SEARCH_SELECT_OPTION.LAMP]: "Hledat dle označení lampy ...",
	[SEARCH_SELECT_OPTION.SWITCH_POINT]: "Hledat dle označení zapínacího bodu ...",
	[SEARCH_SELECT_OPTION.SMART]: "Hledat dle autora, označení objektu ...",
};

export const DiaryListHeader = ({
									onTextChange,
									activeEntryType,
									onActiveEntryTypeChange,
									sortDirection,
									setSortDirection,
									activeSearchType,
									onActiveSearchTypeChange,
									variant,
								}: DiaryListHeaderProps) => {
	const defaultSearchType = onActiveSearchTypeChange ? SEARCH_SELECT_OPTION.SMART : SEARCH_SELECT_OPTION.AUTHOR;

	const handleDirection = (value: string) => {
		setSortDirection(value as SORT_DIRECTION);
	};

	return (

		<div className="d-flex align-items-end justify-content-between mb-2 flex-columsn flex-wrap gap-2">
			<div className="d-flex align-items-center gap-3 flex-grow-1 order-1">
				<div style={{ width: "100%", maxWidth: 300 }}>
					<SearchInput placeholder={MAP_SEARCH_PLACEHOLDER[activeSearchType || defaultSearchType]}
								 onTextChange={onTextChange} />
				</div>
				{onActiveSearchTypeChange && variant && (
					<div className="order-2 order-md-3">
						<div style={{ width: 200, maxWidth: 200 }}>
							<Select
								onChange={onActiveSearchTypeChange}
								options={variant === "lamp" ? SEARCH_SELECT_OPTIONS_LAMP : SEARCH_SELECT_OPTIONS_SWITCH_POINT}
								value={activeSearchType}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="mx-auto order-3 order-md-2">
				<ButtonGroup activeId={activeEntryType} onChange={(key) => onActiveEntryTypeChange(key)}>
					<Button color="primary" variant="outlined" key="all">Všechny</Button>
					<Button color="primary" variant="outlined" key="servis">Servis</Button>
					<Button color="primary" variant="outlined" key="závada">Závada</Button>
					<Button color="primary" variant="outlined" key="revize">Revize</Button>
					<Button color="primary" variant="outlined" key="informace">Info</Button>
				</ButtonGroup>
			</div>
			<div className="order-2 order-md-3">
				<div style={{ width: 130, maxWidth: 130 }}>
					<Select
						onChange={handleDirection}
						options={SORT_DIRECTION_SELECT_OPTIONS}
						value={sortDirection}
					/>
				</div>
			</div>
		</div>
	);
};